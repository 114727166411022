<template>
  <section class="app-ecommerce-details">
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, valIndex) in values" :key="valIndex">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
    <!-- Content -->
    <b-card v-if="itemData" no-body>
      <b-badge
        variant="danger"
        class="p-1"
        style="font-size: 15px"
        v-if="!delCat"
      >
        Archived Data
      </b-badge>
      <b-card-body>
        <b-row class="my-2">
          <!-- Left: Product Image Container -->
          <b-col
            cols="12"
            md="5"
            class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-img
                :src="mainImage"
                :alt="`Image of ${itemTrans_name}`"
                class="product-img"
                fluid
              />
            </div>
          </b-col>
          <!-- Right: Product Details -->
          <b-col cols="12" md="7">
            <!-- Product Name -->
            <h4>
              <span style="color: #046d6d">
                {{ $t("Add_Product.Name") }} :
              </span>
              {{ itemTrans_name }} - {{ itemTrans_name2 }}
            </h4>
            <br />
            <h4>
              <span style="color: #046d6d">
                {{ $t("Add_Product.SKU") }} رمز :
              </span>
              {{ sku }}
            </h4>
            <br />
            <b-card-text class="item-company mb-0">
              <h4>
                <span style="color: #046d6d">
                  {{ $t("Add_Product.Original_Price") }} :
                </span>
                {{ itemData.original_price - itemData.profit  }} EGP
              </h4>
            </b-card-text>
            <br v-if="offer != null" />
            <p
              style="color: #046d6d"
              v-if="offer != null"
              v-show="
                offer.amount != 0 && offer.amount != '' && offer.amount != null
              "
            >
              {{ $t("Add_Product.Offer") }} :
              <b-badge varaint="primary" v-if="offer.type == 'fixed_price'">
                {{ offer.amount }} EGP
              </b-badge>
              <b-badge variant="primary" v-else>{{ offer.amount }}%</b-badge>
            </p>
            <br />
            <b-card-text class="item-company mb-0">
              <h4>
                <span style="color: #046d6d">
                  {{ $t("Add_Product.Sale_Price") }} :
                </span>
                {{ price }}
              </h4>
            </b-card-text>
            <br />
            <b-card-text class="item-company mb-0">
              <h4>
                <span style="color: #046d6d">
                  {{ $t("Add_Product.Profit") }} :
                </span>
                {{ itemData.profit }} EGP
              </h4>
            </b-card-text>
            <br />
            <div class="ecommerce-details-price d-flex flex-wrap mt-1">
              <h4>
                <span style="color: #046d6d">
                  {{ $t("Add_Product.Rate") }} :
                </span>
              </h4>
              <ul class="unstyled-list list-inline pl-1">
                <li
                  v-for="star in 5"
                  :key="star"
                  class="ratings-list-item mr-25"
                >
                  <feather-icon
                    icon="StarIcon"
                    size="18"
                    :class="[
                      { 'fill-current': star <= average_rate },
                      star <= average_rate ? 'text-warning' : 'text-muted',
                    ]"
                  />
                </li>
              </ul>
            </div>
            <br />
            <b-card-text class="item-company mb-0">
              <h4>
                <span style="color: #046d6d">
                  {{ $t("Add_Product.Brand") }} :
                </span>
                <b-link
                  style="color: black"
                  :to="{ path: '/Show-Brand/' + brand_id }"
                >
                  {{ brandTrans_name }}
                </b-link>
              </h4>
            </b-card-text>
            <br />
            <!-- Product Brand -->
            <b-card-text class="item-company mb-0">
              <h4>
                <span style="color: #046d6d">
                  {{ $t("Add_Product.Category") }} :
                </span>
                <b-link
                  style="color: black"
                  :to="{ path: '/Show-Category/' + cat_id }"
                >
                  {{ catTrans_name }}
                </b-link>
              </h4>
            </b-card-text>
            <br />
            <!-- Product Item -->
            <b-card-text class="item-company mb-0">
              <h4>
                <span style="color: #046d6d">
                  {{ $t("Add_Product.Phone_Guide") }} :
                </span>
                <b-link
                  style="color: black"
                  :to="{ path: '/ShowPhoneGuide/' + p_ID }"
                >
                  {{ Pname }}
                </b-link>
              </h4>
            </b-card-text>
            <br />
            <!-- Product -->
            <b-card-text class="item-company mb-0">
              <h4>
                <span style="color: #046d6d">
                  {{ $t("Product_Index.Product") }} :
                </span>
                <b-link
                  style="color: black"
                  :to="{ path: '/Used/ShowProduct/' + product_id }"
                >
                  {{ Pname }}
                </b-link>
              </h4>
            </b-card-text>
            <br />
            <!-- Avability -->
            <b-card-text v-if="itemData.status === 'enabled'">
              <span style="color: #046d6d">
                {{ $t("Add_Product.status") }}
              </span>
              -
              <b-badge variant="success">{{ itemData.status }}</b-badge>
            </b-card-text>
            <b-card-text v-else-if="itemData.battery_health != null">
              <span style="color: #046d6d">
                {{ $t("Add_Product.battery_health") }}
              </span>-
              <b-badge variant="success">{{ itemData.battery_health }}</b-badge>
            </b-card-text>
            <b-card-text v-else-if="itemData.status === 'disabled'">
              <span style="color: #046d6d">
                {{ $t("Add_Product.status") }}
              </span>
              -
              <b-badge variant="danger">{{ itemData.status }}</b-badge>
            </b-card-text>
            <b-card-text v-else-if="itemData.status === 'review'">
              <span style="color: #046d6d">
                {{ $t("Add_Product.status") }}
              </span>
              -
              <b-badge variant="warning">{{ itemData.status }}</b-badge>
            </b-card-text>
            <b-card-text v-else-if="itemData.status === 'archieved'">
              <span style="color: #046d6d">
                {{ $t("Add_Product.status") }}
              </span>
              -
              <b-badge variant="dark">{{ itemData.status }}</b-badge>
            </b-card-text>
            <b-card-text v-else>
              <span style="color: #046d6d">
                {{ $t("Add_Product.status") }}
              </span>
              -
              <b-badge variant="success">{{ itemData.status }}</b-badge>
            </b-card-text>
            <br />
            <b-card-text v-if="itemData.is_insurance === true">
              <span style="color: #046d6d">برنامج الحماية</span>
              -
              <img  v-if="itemData.is_insurance" width="25" class="mx-1" :src="require('@/assets/images/icons/insurance.png')" />
              <!-- <b-badge variant="success">{{ itemData.is_insurance }}</b-badge> -->
            </b-card-text>
            <b-card-text
              ><span style="color: #7367f0">التصنيف </span>-
              <b-badge
                :variant="itemData.is_portal ? 'primary' : 'secondary'"
                >{{ itemData.is_portal ? "بيع  منصة" : "بيع مباشر" }}</b-badge
              >
            </b-card-text>
            <b-card-text
                  v-if="itemData.payment_provider != null"
                  ><span style="color: #7367f0">طريقة الدفع </span>-
                  <b-badge
                    :variant="itemData.payment_provider ? 'primary' : 'secondary'"
                    >{{itemData.payment_provider.name }}</b-badge
                  >
            </b-card-text>

            <b-card-text v-if="itemData.warranty != null" style="color:black;"
              ><span style="color: #7367f0">ضمان </span>-
              {{ itemData.warranty.type }}
            </b-card-text>
            <br />

            <div v-if="is_instore">
              <hr />
  
  
              <div
                v-if=" delCat && !itemData.is_portal && itemData.status === 'enabled'">
                <b-button
                  block
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-1 mt-1"
                  @click="showShare"
                >
                  <span>مشاركة لينك البيع</span>
                </b-button>
              </div>
              <div v-if="delCat" class="d-flex flex-column flex-sm-row">
                <b-button
                  block
                  v-if="itemData.status != 'sold_out' && this.vendorData.can_update_price"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  class="mr-1 mt-1"
                  :to="{
                    path: '/Used/ShowProduct/update/variant/' + itemData.id,
                  }"
                >
                  <feather-icon icon="RefreshCwIcon" class="mr-50" />
                  <span>{{ $t("Add_Product.Update_Main_Data") }}</span>
                </b-button>
                <!-- <b-button
                  block
                  v-show="itemData.status === 'disabled'"
                  @click="activeVariant(itemData.id)"
                  variant="outline-info"
                  class="mr-1 mt-1"
                >
                  Active
                </b-button> -->
                <b-button
                  block
                  v-show="itemData.status === 'enabled'"
                  v-b-modal.modal-deactive
                  variant="outline-info"
                  class="mr-1 mt-1"
                >
                  {{ $t("Add_Product.Deactivate") }}
                </b-button>
                <b-modal
                  id="modal-deactive"
                  cancel-variant="outline-secondary"
                  @ok="deActiveVariant(itemData.id)"
                  ok-title="Ok"
                  cancel-title="Close"
                  centered
                  title="Deactive Varaint"
                >
                  <b-form>
                    <b-form-checkbox
                      id="checkbox-1"
                      v-model="deactive"
                      name="checkbox-1"
                      value="1"
                      unchecked-value="0"
                    >
                      Force Deactive
                    </b-form-checkbox>
                  </b-form>
                </b-modal>
                <b-button
                  block
                  @click="deleteVariant(itemData.id)"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  class="mr-1 mt-1"
                >
                  <feather-icon icon="DeleteIcon" class="mr-50" />
                  <span>{{ $t("Add_Product.Delete_Product") }}</span>
                </b-button>
              </div>
              <div v-else class="d-flex justify-content-center">
                <!-- <b-button
                  block
                  @click="restoreItem(itemData.id)"
                  variant="outline-danger"
                  class="mr-1 mt-1"
                >
                  Restore
                </b-button> -->
              </div>

            </div>


            <hr />
            <div
              v-if="delCat"
              class="d-flex justify-content-around text-center"
            >
              <div class="date-section">
                <h6 class="text-muted font-weight-bolder">
                  {{ $t("Show_Vendor.Created_at") }} :
                </h6>
                <p class="mb-0">
                  {{ itemData.created_at }}
                </p>
              </div>
              <div class="date-section">
                <h6 class="text-muted font-weight-bolder">
                  {{ $t("Show_Vendor.Updated_at") }} :
                </h6>
                <p class="mb-0">
                  {{ itemData.updated_at }}
                </p>
              </div>
            </div>
            <div v-else class="d-flex justify-content-around text-center">
              <div class="date-section">
                <h6 class="text-muted font-weight-bolder">
                  {{ $t("Show_Vendor.Created_at") }} :
                </h6>
                <p class="mb-0">
                  {{ itemData.created_at }}
                </p>
              </div>
              <div class="date-section">
                <h6 class="text-muted font-weight-bolder">deleted at :</h6>
                <p class="mb-0">
                  {{ itemData.deleted_at }}
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card :title="$t('Add_Product.Reports')">
      <!-- start data table -->
      <b-col cols="12">
        <b-table striped hover responsive :items="items" :fields="fields2">
          <template #cell(user)="items">
            <b-badge
              size="lg"
              v-if="items.value.includes('Vendor:')"
              variant="primary"
            >
              {{ items.value }}
            </b-badge>
            <b-badge size="lg" v-else variant="info">{{ items.value }}</b-badge>
          </template>

          <template #cell(actions)="items">
            <div class="demo-inline-spacing">
              <b-button
                style="padding: 7px; margin: 6px; width: 35px; height: 35px"
                :to="{ path: '/diagnostic/reports/show/' + items.item.id }"
                variant="success"
                class="btn-icon rounded-circle"
              >
                <feather-icon
                  icon="EyeIcon"
                  style="width: 18px; height: 18px"
                />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-card>

    <!-- tags section -->
    <b-card v-if="itemData.is_portal" :title="$t('Add_Product.Tag')">
      <div v-for="(tag, index) in tags" class="d-inline p-2" :key="index">
        <span style="font-size: 17px">
          <b-badge
            variant="light-primary"
            style="padding: 10px"
            class="badge-glow badge-lg"
          >
            {{ tag.name }}
          </b-badge>
        </span>
      </div>
    </b-card>
    <b-card :title="$t('Add_Product.Prices')">
      <!-- prices echart -->
      <!-- <app-echart-line :option-data="optionDiagram" /> -->
      <!-- prices table -->
      <b-table
        :sticky-header="stickyHeader"
        :no-border-collapse="noCollapse"
        responsive
        :items="itemData.price_loggers"
        :fields="fields"
      ></b-table>
    </b-card>
    <!-- Description section -->
    <b-card v-if="itemData.is_portal" :title="$t('Add_Product.Description')">
      <b-tabs align="center" class="text-center">
        <b-tab
          v-for="(translation, index) in itemTrans"
          :key="index"
          :title="translation.locale == 'en' ? 'English' : 'Arabic'"
        >
          <b-card-text><p v-html="translation.description"></p></b-card-text>
        </b-tab>
      </b-tabs>
      <!-- add new translation -->
    </b-card>

    <!-- photo gallery section -->
    <CoolLightBox
      :items="photoGallery"
      :index="imgIndex"
      @close="imgIndex = null"
    ></CoolLightBox>
    <b-card v-if="itemData.is_portal" :title="$t('Add_Product.Media')">
      <b-row>
        <b-col v-if="videoSect" cols="12">
          <h2>Video</h2>
          <video-player
            class="video-player-box"
            ref="videoPlayer"
            :options="playerOptions"
            :playsinline="true"
            customEventName="customstatechangedeventname"
            @play="onPlayerPlay($event)"
            @pause="onPlayerPause($event)"
            @statechanged="playerStateChanged($event)"
            @ready="playerReadied"
          ></video-player>
        </b-col>
        <b-col class="mt-2" cols="12">
          <h2>{{ $t("Add_Product.Photo_gallery") }}</h2>
          <swiper
            class="swiper-autoplay"
            :options="swiperOptions"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          >
            <swiper-slide v-for="(data, index) in photoGallery" :key="index">
              <b-img
                @click="imgIndex = index"
                width="150"
                height="auto"
                :src="data.src"
              />
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <b-button
                  v-if="is_instore"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  class="btn-icon ml-1 mt-1"
                  @click="deleteImages(data.id)"
                >
                  Delete
                </b-button>
              </div>
            </swiper-slide>
            <div slot="pagination" class="swiper-pagination" />
          </swiper>
          <b-button
            v-if="is_instore"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="success"
            block
            class="btn-cart mt-3"
            :to="{ path: '/Used/ShowProduct/variant/add/image/' + itemData.id }"
          >
            <feather-icon icon="RefreshCwIcon" class="mr-50" />
            <span>{{ $t("Add_Product.Add_New_Image") }}</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <!-- share  -->
    <b-modal size="lg" id="modal-center" ref="show-share" hide-footer centered>
      <b-card>
        <b-row>
          <b-col md="4" class="d-flex justify-content-center mb-1">
            <qr-code size="200" :text="share_link"></qr-code>
          </b-col>
          <b-col md="8">
            <b-row class="mb-2">
              <b-col cols="3" class="text-primary font-weight-bold"
                >الإسم</b-col
              >
              <b-col
                cols="9"
                class="border border-primary rounded text-primary py-1"
                >{{ PhGuide }}</b-col
              >
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3" class="text-primary font-weight-bold"
                >الماركة</b-col
              >
              <b-col
                cols="9"
                class="border border-primary rounded text-primary py-1"
                >{{ brandTrans_name }}</b-col
              >
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3" class="text-primary font-weight-bold"
                >الفئات</b-col
              >
              <b-col
                cols="9"
                class="border border-primary rounded text-primary py-1"
                >{{ catTrans_name }}</b-col
              >
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3" class="text-primary font-weight-bold"
                >السعر</b-col
              >
              <b-col
                cols="9"
                class="border border-primary rounded text-primary py-1"
                ><span class="text-secondary font-weight-bold h3"
                  >{{ itemData.sale_price }}
                </span>
                جنيه مصري
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12">
            <p class="text-primary mb-0">رابط شراء المنتج</p>
            <div class="d-flex justify-content-start">
              <p
                id="myInput"
                v-if="share_link"
                class="text-primary p-1 mb-0 border border-primary rounded"
              >
                {{ share_link.slice(0, 75) + "..." }}
              </p>
              <b-button @click="copy" variant="none">
                <feather-icon size="20" icon="CopyIcon" /> نسخ
              </b-button>
            </div>

            <p class="text-secondary mb-0">هذا الرابط صالح لمدة 3 ساعة فقط</p>
          </b-col>

          <b-col cols="12" class="text-center p-1">
            <p class="h3 text-secondary mb-1 font-weight-bold text-center">
              مشاركة الرابط
            </p>
            <b-row>
              <b-col
                class="mb-2"
                v-for="network in networks"
                :key="network.network"
              >
                <ShareNetwork
                  :network="network.network"
                  :style="{ backgroundColor: network.color }"
                  :url="share_link"
                  :title="'MobileMasr'"
                  :description="'بيع مباشر'"
                  class="h-100 w-100"
                >
                  <span class="text-white p-2">{{ network.name }}</span>
                </ShareNetwork>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>
  </section>
</template>

<script>
import PrdouctsTable from "@/Components/PrdouctsTable";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
export default {
  name: "MyComponent",

  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  components: {
    CoolLightBox,
    Swiper,
    SwiperSlide,
    PrdouctsTable,
  },
  data() {
    return {
      networks: [
        {
          network: "whatsapp",
          name: "Whatsapp",
          color: "#25d366",
        },
        {
          network: "facebook",
          name: "Facebook",

          color: "#1877f2",
        },

        {
          network: "messenger",
          name: "Messenger",

          color: "#0084ff",
        },

        {
          network: "email",
          name: "Email",

          color: "#333333",
        },

        {
          network: "sms",
          name: "SMS",

          color: "#333333",
        },

        {
          network: "telegram",
          name: "Telegram",

          color: "#0088cc",
        },
      ],
      // optionDiagram: {
      //   xAxisData: [],
      //   series: [],
      // },
      tags: null,
      stickyHeader: true,
      noCollapse: true,
      fields: [
        {
          key: "id",
          stickyColumn: true,
          isRowHeader: true,
          variant: "primary",
        },
        {
          key: "price",
          label: this.$t("Table_Head.Price"),
        },
        {
          key: "date",
          label: this.$t("Table_Head.Date"),
        },
      ],
      options: [],
      deactive: "0",
      deletedProduct: false,
      videoSect: false,
      trashed: false,
      playerOptions: {
        // videojs options
        muted: false,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src: "",
          },
        ],
        poster: "",
      },
      related: [],
      relatedParent: false,
      varAR: [],
      varEN: [],
      p_ID: 0,
      ven_id: 0,
      ven_type: "",
      brand_id: 0,
      cat_id: 0,
      delCat: true,
      itemIDV: "",
      parentSet: true,
      showImg: false,
      imgIndex: null,
      mainImage: "",
      id: 0,
      itemData: {},
      itemComments: [],
      errors_back: [],
      showDismissibleAlert: false,
      itemTrans: "",
      itemDesc: null,
      itemTrans_name: null,
      itemTrans_name2: null,
      catTrans: null,
      catTrans_name: null,
      brandTrans: null,
      brandTrans_name: null,
      taxonomies: [],
      Catactive: [],
      price: "",
      active: "",
      ShortDesc: "",
      ShortDesc2: "",
      ShortDesc3: "",
      parentSKU: "",
      parentID: 0,
      sku: "",
      average_rate: "",
      VendName: "",
      vendorType: "",
      PhGuide: "",
      PhGuide_Desc: "",
      vendorcover: "",
      parentCover: "",
      phonegudieCover: "",
      VendCode: "",
      length: "",
      width: "",
      height: "",
      weight: "",
      mass_unit: "",
      distance_unit: "",
      storename: "",
      address: "",
      quantity: "",
      Pdesc: "",
      Pname: "",
      aRate: "",
      offer: null,
      variants: null,
      prdouct_name: null,
      product_id: null,
      option: {
        xAxisData: [],
        series: [],
      },
      photoGallery: [],
      photoGallery2: [],
      settings: {
        dots: true,
        focusOnSelect: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        touchThreshold: 5,
      },
      items: [],
      fields2: [
        {
          key: "id",
          label: this.$t("Table_Head.ID"),
          sort_by: true,
        },
        {
          key: "user",
          label: this.$t("Table_Head.User/Vendor"),
        },
        {
          key: "type",
          label: this.$t("Table_Head.Type"),
        },
        {
          key: "variant_id",
          label: this.$t("Table_Head.Variant_ID"),
        },
        {
          key: "serial_number",
          label: this.$t("Table_Head.Serial_Number"),
        },
        {
          key: "score",
          label: this.$t("Table_Head.Score"),
        },
        {
          key: "score",
          label: this.$t("Table_Head.Score"),
        },
        {
          key: "actions",
          label: this.$t("Table_Head.Actions"),
        },
      ],
      stickyHeader: true,
      noCollapse: true,
      fields: [
        {
          key: "id",
          label: this.$t("Table_Head.ID"),
          stickyColumn: true,
          isRowHeader: true,
          variant: "primary",
        },
        {
          key: "price",
          label: this.$t("Table_Head.Price"),
        },
        {
          key: "created_at",
          label: this.$t("Table_Head.Date"),
        },
      ],
      swiperOptions: {
        freeMode: false,
        slidesPerView: "auto",
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: ".swiper-pagination",
        },
      },
      currentPage: 0,
      restoreSelected: {
        variants: [],
      },
      currentPage2: 0,
      options2: [],
      restoreSelected2: {
        variants: [],
      },
      share_link: null,
      vendorData:null,
      is_instore: false
    };
  },
  async created() {
    this.vendorData = this.$store.state.vendorData

    this.showDismissibleAlert = false;
    this.id = this.$route.params.id;
    await this.fetchData();
   
    // this.getAllVariantsInSystem()
    // this.getAllTrashedVariantsInSystem()
  },
  methods: {
  
    
    copy() {
      // let text = document.getElementById('myText').innerHTML;

      navigator.clipboard.writeText(this.share_link);
      this.$swal({
        position: "center",
        icon: "success",
        title: "تم النسخ",
        showConfirmButton: false,
        timer: 1500,
      });
    },
    showShare() {
      axios
        .get("variants/" + this.id + "/in_store/link/generate")
        .then((result) => {
          this.share_link = result.data.data.link;
          this.$refs["show-share"].show();
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    fetchData() {
      axios
        .get("variants/" + this.id + "/show")
        .then((result) => {
          this.itemData = result.data.data;
          if (this.itemData.Media.length > 0) {
            // console.log("true")
            this.mainImage = this.itemData.Media[0].name;
            // console.log("mainImage"  , this.itemData.Media )
            for (let img in this.itemData.Media) {
              if (this.itemData.Media[img].type != "video") {
                
                this.photoGallery.push({
                  src: this.itemData.Media[img].name,
                  id: this.itemData.Media[img].id,
                });
              }
            }
          } else {
            this.mainImage = this.itemData.cover;
          }
          this.checkCategory();
          // set video data
          if (this.itemData.video != null) {
            this.videoSect = true;
            this.playerOptions.sources[0].src = this.itemData.video.name;
          }
          this.offer = this.itemData.offer;
          if (this.itemData.deleted_at != null) {
            this.delCat = false;
          } else {
            this.delCat = true;
          }
          this.tags = this.itemData.product.tags;

          this.itemIDV = this.itemData.id.toString();
          this.price = this.itemData.price + " EGP";
          if (this.itemData.translation.length > 0) {
            this.ShortDesc = this.itemData.translation[0].short_description;
            this.itemTrans = this.itemData.translation;
            this.itemTrans_name = this.itemData.translation[0].name;
          }
          this.sku = this.itemData.sku;
          this.average_rate = this.itemData.average_rate;
          if (this.itemData.product.item.category.translation != null) {
            this.catTrans_name =
              this.itemData.product.item.category.translation.name;
          }
          this.brand_id = this.itemData.product.item.brand.id;
          this.cat_id = this.itemData.product.item.category.id;
          if (this.itemData.product.item.brand.translation != null) {
            this.brandTrans_name =
              this.itemData.product.item.brand.translation.name;
          }
          this.VendName =
            this.itemData.product.vendor.firstname +
            " " +
            this.itemData.product.vendor.lastname;
          this.VendCode = this.itemData.product.vendor.code;
          this.parentID = this.itemData.product.id;
          if (this.itemData.product.translation != null) {
            this.PhGuide = this.itemData.product.translation.name;
            this.ShortDesc2 =
              this.itemData.product.translation.short_description;
          }
          this.parentCover = this.itemData.product.cover;
          this.parentSKU = this.itemData.product.sku;
          this.aRate = this.itemData.product.average_rate;
          this.ven_id = this.itemData.product.vendor.id;
          this.ven_type = this.itemData.product.vendor.type;
          this.vendorcover = this.itemData.product.vendor.profile_picture;
          this.vendorType = this.itemData.product.vendor.type;
          this.phonegudieCover = this.itemData.product.item.cover;
          this.p_ID = this.itemData.product.item.id;
          this.Pname = this.itemData.product.item.item_translations.name;
          this.Pdesc =
            this.itemData.product.item.item_translations.short_description;
          this.product_name = this.itemData.product.translation.name;
          this.product_id = this.itemData.product.id;
          if (this.itemData.product.shipping != null) {
            this.length = this.itemData.product.shipping.length;
            this.width = this.itemData.product.shipping.width;
            this.height = this.itemData.product.shipping.height;
            this.weight = this.itemData.product.shipping.weight;
            this.distance_unit = this.itemData.product.shipping.distance_unit;
            this.mass_unit = this.itemData.product.shipping.mass_unit;
          }
          // this.storename = this.itemData.product.vendor.storename
          // this.address = this.itemData.product.vendor.address
          this.quantity = this.itemData.quantity;
          // this.phname = this.itemData.product.item.name

          this.items.length = 0;
          if (this.itemData.reports.length > 0) {
            for (let index in this.itemData.reports) {
              this.items.push({
                id: this.itemData.reports[index].id,
                user:
                  this.itemData.reports[index].user != null
                    ? "User: " +
                      this.itemData.reports[index].user.firstname +
                      " " +
                      this.itemData.reports[index].user.lastname
                    : "Vendor: " +
                      this.itemData.reports[index].vendor.storename,
                type: this.itemData.reports[index].type,
                variant_id: this.itemData.reports[index].variant_id,
                serial_number: this.itemData.reports[index].serial_number,
                score: this.itemData.reports[index].score + " %",
                brand_name: this.itemData.reports[index].brand_name,
              });
            }
          }
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    deleteVariant(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete variant.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete("variants/" + id + "/delete")
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    deleteVariant(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete varaint.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete("actions/variants/" + id + "/archive")
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    deleteImages(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete image.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete("variants/media/" + id + "/delete")
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    deleteReview(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete comment.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete("variants/reviews/" + id + "/delete")
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    activeVariant(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to active varaint.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "info",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get("variants/" + id + "/active-variant")
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    deActiveVariant(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to deactive varaint.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "info",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get("actions/variants/" + id + "/deactive")
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    restoreItem(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to restore variant.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "warning",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get("variants/" + id + "/restore")
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    // listen event
    onPlayerPlay(player) {},
    onPlayerPause(player) {},
    // ...player event
    // or listen state event
    playerStateChanged(playerCurrentState) {},
    // player is ready
    playerReadied(player) {
      // you can use it to do something...
      // player.[methods]
    },
    checkCategory(){
        var type = '';
        if(this.itemData.is_new == true){ 
         if(this.itemData.is_portal){
          type ='is_portal_new';
         }else{
           type ='is_instore_new';
         }
        }else{
          if(this.itemData.is_portal){
          type ='is_portal_used';
          }else{
            type ='is_instore_used';
          }
        }

            axios
            .post("/checkCategory/"+ this.itemData.product.item.category.id,{ type: type})
            .then((result) => {
              this.is_instore = result.data.data;
            })
            .catch((err) => {
              this.errors_back.length = 0;
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  });
                } else {
                  this.errors_back = err.response.data.data;
                }
                this.showDismissibleAlert = true;
              } else {
                this.errors_back = [];
                this.errors_back.push({
                  error: "internal server error",
                });
                this.showDismissibleAlert = true;
              }
            });
      },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>

<style>
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0px !important;
}
.swiper-container {
  padding: 30px !important;
}
[dir] .video-js {
  width: 100% !important;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 320px) {
  .swiper-slide {
    max-width: calc(100% / 1.6);
  }
}
@media only screen and (min-width: 321px) and (max-width: 400px) {
  .swiper-slide {
    max-width: calc(100% / 1.6);
  }
}
@media only screen and (min-width: 401px) and (max-width: 500px) {
  .swiper-slide {
    max-width: calc(100% / 1.8);
  }
}
@media only screen and (min-width: 501px) and (max-width: 640px) {
  .swiper-slide {
    max-width: calc(100% / 2.3);
  }
}
@media only screen and (min-width: 641px) and (max-width: 768px) {
  .swiper-slide {
    max-width: calc(100% / 3);
  }
}
@media only screen and (min-width: 769px) and (max-width: 890px) {
  .swiper-slide {
    max-width: calc(100% / 3);
  }
}
@media only screen and (min-width: 891px) and (max-width: 1024px) {
  .swiper-slide {
    max-width: calc(100% / 4);
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .swiper-slide {
    max-width: calc(100% / 5);
  }
}
@media only screen and (min-width: 1441px) {
  .swiper-slide {
    max-width: calc(100% / 5);
  }
}
</style>
