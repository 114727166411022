<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-card
        v-if="data != null"
        :title="$t('Add_Product.Update_Variant')"
        ref="tranHeight"
      >
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col md="1">
                <div class="d-flex justify-content-center align-items-center">
                  <b-img fluid :src="data.cover"></b-img>
                </div>
              </b-col>
              <b-col class="my-auto" md="9">
                <h4>
                  <span style="color: #7367f0"
                    >{{ $t("Add_Product.Name") }} :</span
                  >
                  {{ variantData.translation.name }}
                </h4>
                <br />
                <h4>
                  <span style="color: #7367f0"
                    >{{ $t("Add_Product.SKU") }} رمز :
                  </span>
                  {{ variantData.sku }}
                </h4>
                <br />
                <h4>
                  <span style="color: #7367f0">
                    {{ $t("Add_Product.status") }} :
                  </span>
                  {{ variantData.status }}
                </h4>
                <br />
                <h4>
                  <span style="color: #7367f0">
                    التصنيف :
                  </span>
                  {{ variantData.is_portal ? "بيع  منصة" : "بيع مباشر" }}
                </h4>
                <br />
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12">
            <b-row>
              <b-col md="12" class="">
                <b-row>
                  <b-col
                    md="2"
                    class="d-flex justify-content-center align-items-center"
                  >
                    <b-button
                      variant="warning"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-modal.modal-prevent-closing
                      >{{ $t("Add_Product.Add_Price") }}</b-button
                    >
                  </b-col>
                  <b-col
                    md="10"
                    v-show="variant.salePrice > 0 && variant.salePrice != null"
                  >
                    <p>
                      {{
                        variant.is_portal == "Portal"
                          ? $t("Add_Product.Original_Price")
                          : "سعر البائع"
                      }}
                      : {{ variant.original_price }} EGP
                    </p>
                    <p
                      v-show="
                        variant.offer.amount != 0 &&
                          variant.offer.amount != '' &&
                          variant.offer.amount != null
                      "
                    >
                      {{ $t("Add_Product.Offer") }} :
                      <b-badge
                        varaint="primary"
                        v-if="variant.offer.type == 'fixed_price'"
                        >{{ variant.offer.amount }} EGP</b-badge
                      >
                      <b-badge variant="primary" v-else
                        >{{ variant.offer.amount }}%</b-badge
                      >
                    </p>
                    <p v-if="variant.is_portal">
                      {{ $t("Add_Product.Sale_Price") }} :
                      {{ variant.salePrice }} EGP
                    </p>
                    <p>
                      {{
                        variant.is_portal
                          ? $t("Add_Product.Profit")
                          : "عمولة الخدمة"
                      }}
                      : {{ variant.profit }} EGP
                    </p>

                    <p
                      v-if="!variant.is_portal"
                      class="text-dark font-weight-bold"
                    >
                      سعر البيع النهائى :
                      {{
                        parseFloat(variant.original_price) +
                          parseFloat(variant.profit)
                      }}
                      جنيه
                    </p>
                  </b-col>

                  <b-col cols="12">
                    <hr />
                  </b-col>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="success()"
                  >
                    <span>حفظ البيانات </span>
                  </b-button>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-modal
          id="modal-prevent-closing"
          cancel-variant="outline-secondary"
          @ok="handleOk"
          ok-title="أضف"
          cancel-title="أغلق"
          centered
          title="Add Price Form"
          size="lg"
        >
          <validation-observer ref="addPrice">
            <b-form @submit.stop.prevent="addPriceRate">
              <b-row>
                <!-- varint price -->
                <b-col md="6">
                  <b-form-group
                    :label="$t('Add_Product.Price')"
                    label-for="price"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="price"
                      rules="required|regex:\b\d+(?:\.\d\d?)?\b"
                    >
                      <b-form-input
                        v-model="variant.original_price"
                        min="0"
                        type="number"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- varint Amount -->
                <b-col md="6" v-if="variant.is_portal">
                  <b-form-group
                    :label="$t('Add_Product.Amount')"
                    label-for="amount"
                  >
                    <b-form-input
                      min="1"
                      type="number"
                      v-model="variant.offer.amount"
                      :placeholder="$t('Add_Product.Amount')"
                    />
                  </b-form-group>
                </b-col>
                <b-col v-if="variant.offer.amount > 0" md="12">
                  <b-row>
                    <b-col>
                      <app-collapse
                        id="faq-payment-qna"
                        type="margin"
                        class="mt-0 mb-0"
                      >
                        <app-collapse-item
                          key="variantIndex"
                          title="Click To Customize Offer"
                        >
                          <b-row>
                            <!-- vendors -->
                            <b-col md="6">
                              <validation-provider
                                #default="{ errors }"
                                name="Offer Type"
                                rules="required"
                              >
                                <b-form-group
                                  :label="$t('Add_Product.Offer_Type')"
                                  label-for="Offer Type"
                                  :state="errors.length > 0 ? false : null"
                                >
                                  <v-select
                                    id="Offer-Type"
                                    v-model="offerTypeSelect"
                                    :state="
                                      offerTypeSelect === null ? false : true
                                    "
                                    :dir="
                                      $store.state.appConfig.isRTL
                                        ? 'rtl'
                                        : 'ltr'
                                    "
                                    :options="offerTypeOptions"
                                    :selectable="
                                      (option) =>
                                        !option.value.includes('select_value')
                                    "
                                    label="text"
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                            <b-col md="6">
                              <b-form-group
                                :label="$t('Add_Product.User_Numbers')"
                                label-for="User-Numbers"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  name="user numbers"
                                  rules="required|regex:^[0]*[1-9][0-9]*$"
                                >
                                  <b-form-input
                                    min="1"
                                    id="User-Numbers"
                                    type="number"
                                    v-model="variant.offer.usernumbers"
                                    :state="errors.length > 0 ? false : null"
                                    :placeholder="
                                      $t('Add_Product.User_Numbers')
                                    "
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="6">
                              <validation-provider
                                #default="{ errors }"
                                name="start date"
                                rules="required"
                              >
                                <b-form-group
                                  :label="$t('Add_Product.Start_Date')"
                                  label-for="start date"
                                  :state="errors.length > 0 ? false : null"
                                >
                                  <flat-pickr
                                    v-model="variant.offer.startdate"
                                    class="form-control"
                                    :config="{
                                      enableTime: true,
                                      dateFormat: 'Y-m-d H:i',
                                    }"
                                  />
                                  <b-form-invalid-feedback
                                    :state="errors.length > 0 ? false : null"
                                  >
                                    {{ errors[0] }}
                                  </b-form-invalid-feedback>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                            <b-col md="6">
                              <validation-provider
                                #default="{ errors }"
                                name="end date"
                                rules="required"
                              >
                                <b-form-group
                                  :label="$t('Add_Product.End_Date')"
                                  label-for="end date"
                                  :state="errors.length > 0 ? false : null"
                                >
                                  <flat-pickr
                                    v-model="variant.offer.enddate"
                                    class="form-control"
                                    :config="{
                                      enableTime: true,
                                      dateFormat: 'Y-m-d H:i',
                                    }"
                                  />
                                  <b-form-invalid-feedback
                                    :state="errors.length > 0 ? false : null"
                                  >
                                    {{ errors[0] }}
                                  </b-form-invalid-feedback>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                          </b-row>
                        </app-collapse-item>
                      </app-collapse>
                    </b-col>
                  </b-row>
                  <!-- error handelr -->
                  <b-alert
                    v-height-fade.appear
                    fade
                    :show="showDismissibleAlert2"
                    @dismissed="showDismissibleAlert2 = false"
                    variant="danger"
                  >
                    <h4 class="alert-heading">Alert</h4>
                    <div class="alert-body">
                      <ul v-for="(values, index) in errors_back" :key="index">
                        <li v-for="(value, valIndex) in values" :key="valIndex">
                          {{ value }}
                        </li>
                      </ul>
                    </div>
                  </b-alert>
                </b-col>
              </b-row>
                <b-row  v-if="!variant.is_portal">
                      <h5 class="text-left m-1"> طريقة الدفع </h5>
                        <validation-provider
                                #default="{ errors }"
                                name="payment Method"
                                rules="required"
                                class="w-100"
                                v-if="isAnyProviderValid"
                              >
                        <b-form-radio-group v-model="variant.payment_provider_id" name="paymentOption" class="w-100">
                        <b-col md="12"   v-if="bankInstallmentProvider" >
                              <b-card >
                                  <!-- Option 1: Bank Installment -->
                                  <b-form-radio :value="bankInstallmentProvider.PaymentProvider.id" class="w-100 d-flex align-items-center p-0">
                                    <div class="d-flex justify-content-between w-100 align-items-center">
                                      <div class="d-flex align-items-left">
                                        <img  src="@/assets/images/icons/bank_installment.svg" alt="Bank Installment Icon" class="icon mr-1 max-width-42">
                                        <span class="mr-1 text-left text-secondary"> تقسيط بنكي</span>
                                      </div>
                                      <div class="font-small-2">
                                        <div class="price-info">
                                        <span class="m-0 text-nowrap">سعر البائع: {{ variant.original_price }} EGP</span>
                                        <span class="m-0 text-nowrap">
                                          سعر المنصه:
                                          <span v-if="variant.original_price != ''">
                                            {{
                                              sale_price = valuProvider.type == 'percentage'
                                                ? ((Number(variant.original_price) * valuProvider.rate) / 100 +
                                                    Number(variant.original_price))
                                                : variant.original_price + valuProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                        <span class="text-success m-0 text-nowrap">
                                          عمولة المنصه:
                                          <span v-if="variant.original_price != ''">
                                            {{
                                              valuProvider.type == 'percentage'
                                                ? (Number(variant.original_price) * valuProvider.rate) / 100
                                                : valuProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                      </div>


                                        
                                       <!-- Scrolling Container -->
                                       <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_CIB'">
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px;  padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'CIB'"
                                            >
                                            <div class="pt-1">
                                              <b-img width="50" height="40px" src="@/assets/images/PaymentBanks/cib.svg"></b-img>
                                            </div>
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_CIB'"
                                           class="installment-plan-item mb-0"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>

                                          <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_Mashreq'">
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px;   padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'Mashreq'"
                                            >
                                            <div class="pt-1">
                                              <b-img width="50" height="40px" src="@/assets/images/PaymentBanks/mashreq.svg"></b-img>

                                            </div>
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_Mashreq'"
                                           class="installment-plan-item mb-0"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>


                                          <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_Nbd'">
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px;   padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'Nbd'"
                                            >
                                            <div class="pt-1">
                                              <b-img width="50" height="40px" src="@/assets/images/PaymentBanks/nbd.svg"></b-img>
                                            </div>
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_Nbd'"
                                           class="installment-plan-item mb-0"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>


                                          <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_fab'">
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px;   padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'Fab'"
                                            >
                                            <div class="pt-1">
                                              <b-img width="50" height="40px" src="@/assets/images/PaymentBanks/nbd.svg"></b-img>

                                            </div>
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_Fab'"
                                           class="installment-plan-item mb-0"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>

                                          <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_NBK'">
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px;   padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'NBK'"
                                            >
                                            <div class="pt-1">
                                              <b-img width="50" height="40px" src="@/assets/images/PaymentBanks/nbk.svg"></b-img>

                                            </div>
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_NBK'"
                                           class="installment-plan-item mb-0"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>
                                    


                                      </div>
                                    </div>
                                  </b-form-radio>
                              </b-card>
                            <!-- Add more options similarly -->
                        
                      </b-col>
                        <b-col md="12">
                       
                            <h5 class="text-left m-1"> تقسيط الشركات </h5>
                              <b-card   class="change-padding p-0"  v-if="valuProvider">
                                <!-- Option 1: Bank Installment -->
                                <b-form-radio :value="valuProvider.PaymentProvider.id" class="w-100 d-flex align-items-center ">
                                  <div class="d-flex justify-content-between w-100 align-items-center ">
                                    <div class="d-flex align-items-left">
                                      <img  src="@/assets/images/icons/valu_icon.svg" alt="Company Icon" class="icon mr-1 max-width-42">

                                    </div>
                                    <div class="font-small-2">
                                      <div class="price-info">
                                        <span class="m-0 text-nowrap">سعر البائع: {{ variant.original_price }} EGP</span>
                                        <span class="m-0 text-nowrap">
                                          سعر المنصه:
                                          <span v-if="variant.original_price != ''">
                                            {{
                                              sale_price = valuProvider.type == 'percentage'
                                                ? ((Number(variant.original_price) * valuProvider.rate) / 100 +
                                                    Number(variant.original_price))
                                                : variant.original_price + valuProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                        <span class="text-success m-0 text-nowrap">
                                          عمولة المنصه:
                                          <span v-if="variant.original_price != ''">
                                            {{
                                              valuProvider.type == 'percentage'
                                                ? (Number(variant.original_price) * valuProvider.rate) / 100
                                                : valuProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                      </div>
                                     <!-- Scrolling Container -->
                                     <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_valu'"
                                          >
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px;  padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'valu'"
                                            >
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_valu'"
                                           class="installment-plan-item mb-0"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>
                                      </div>
                                  </div>
                                </b-form-radio>
                            </b-card>

                            <b-card  class="change-padding p-0" v-if="contactProvider">
                                <!-- Option 1: Bank Installment -->
                                <b-form-radio :value="contactProvider.PaymentProvider.id" class="w-100 d-flex align-items-center p-0">
                                  <div class="d-flex justify-content-between w-100 align-items-center">
                                    <div class="d-flex align-items-left">
                                      <img  src="@/assets/images/icons/contact_icon.svg" alt="Company Icon" class="icon mr-1 max-width-42">

                                    </div>
                                    <div class="font-small-2">
                                      <div class="price-info">
                                        <span class="m-0 text-nowrap">سعر البائع: {{ variant.original_price }} EGP</span>
                                        <span class="m-0 text-nowrap">
                                          سعر المنصه:
                                          <span v-if="variant.original_price != ''">
                                            {{
                                              sale_price =  valuProvider.type == 'percentage'
                                                ? ((Number(variant.original_price) * valuProvider.rate) / 100 +
                                                    Number(variant.original_price))
                                                : variant.original_price + valuProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                        <span class="text-success m-0 text-nowrap">
                                          عمولة المنصه:
                                          <span v-if="variant.original_price != ''">
                                            {{
                                              valuProvider.type == 'percentage'
                                                ? (Number(variant.original_price) * valuProvider.rate) / 100
                                                : valuProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                      </div>
                                       <!-- Scrolling Container -->
                                       <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_contact'"
                                          >
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px; padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'contact'"
                                            >
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_contact'"
                                           class="installment-plan-item mb-0"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>
                                    
                                      </div>
                                  </div>
                                </b-form-radio>
                            </b-card>


                            
                            <b-card  class="change-padding p-0" v-if="amanProvider">
                                <!-- Option 1: Bank Installment -->
                                <b-form-radio :value="amanProvider.PaymentProvider.id" class="w-100 d-flex align-items-center p-0">
                                  <div class="d-flex justify-content-between w-100 align-items-center">
                                    <div class="d-flex align-items-left">
                                      <img  src="@/assets/images/icons/aman_icon.svg" alt="Company Icon" class="icon mr-1 max-width-42">

                                    </div>
                                    <div class="font-small-2">
                                      <div class="price-info">
                                        <span class="m-0 text-nowrap">سعر البائع: {{ variant.original_price }} EGP</span>
                                        <span class="m-0 text-nowrap">
                                          سعر المنصه:
                                          <span v-if="variant.original_price != ''">
                                            {{
                                              sale_price = valuProvider.type == 'percentage'
                                                ? ((Number(variant.original_price) * valuProvider.rate) / 100 +
                                                    Number(variant.original_price))
                                                : variant.original_price + valuProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                        <span class="text-success m-0 text-nowrap">
                                          عمولة المنصه:
                                          <span v-if="variant.original_price != ''">
                                            {{
                                              valuProvider.type == 'percentage'
                                                ? (Number(variant.original_price) * valuProvider.rate) / 100
                                                : valuProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                      </div>
                                     <!-- Scrolling Container -->
                                     <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_aman'"
                                          >
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px; padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'aman'"
                                            >
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_aman'"
                                           class="installment-plan-item mb-0"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>
                                      </div>
                                  </div>
                                </b-form-radio>
                            </b-card>

                            <b-card class="change-padding p-0" v-if="shoolaProvider">
                                <!-- Option 1: Bank Installment -->
                                <b-form-radio :value="shoolaProvider.PaymentProvider.id" class="w-100 d-flex align-items-center p-0">
                                  <div class="d-flex justify-content-between w-100 align-items-center">
                                    <div class="d-flex align-items-left">
                                      <img  src="@/assets/images/icons/sahoola_icon.svg" alt="Company Icon" class="icon mr-1 max-width-42">

                                    </div>
                                    <div class="font-small-2">
                                      <div class="price-info">
                                        <span class="m-0 text-nowrap">سعر البائع: {{ variant.original_price }} EGP</span>
                                        <span class="m-0 text-nowrap">
                                          سعر المنصه:
                                          <span v-if="variant.original_price != ''">
                                            {{
                                              sale_price = valuProvider.type == 'percentage'
                                                ? ((Number(variant.original_price) * valuProvider.rate) / 100 +
                                                    Number(variant.original_price))
                                                : variant.original_price + valuProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                        <span class="text-success m-0 text-nowrap">
                                          عمولة المنصه:
                                          <span v-if="variant.original_price != ''">
                                            {{
                                              valuProvider.type == 'percentage'
                                                ? (Number(variant.original_price) * valuProvider.rate) / 100
                                                : valuProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                      </div>
                                    
                                        <!-- Scrolling Container -->
                                        <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_souhoola'" >
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px; padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'souhoola'"
                                            >
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_souhoola'"
                                           class="installment-plan-item mb-0"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>


                                      </div>
                                  </div>
                                </b-form-radio>
                            </b-card>

                            <b-card class="change-padding p-0" v-if="forsaProvider">
                                <!-- Option 1: Bank Installment -->
                                <b-form-radio :value="forsaProvider.PaymentProvider.id" class="w-100 d-flex align-items-center p-0">
                                  <div class="d-flex justify-content-between w-100 align-items-center">
                                    <div class="d-flex align-items-left">
                                      <img  src="@/assets/images/icons/forsa_icon.svg" alt="Company Icon" class="icon mr-1 max-width-42">

                                    </div>
                                    <div class="font-small-2">
                                      <div class="price-info">
                                        <span class="m-0 text-nowrap">سعر البائع: {{ variant.original_price }} EGP</span>
                                        <span class="m-0 text-nowrap">
                                          سعر المنصه:
                                          <span v-if="variant.original_price != ''">
                                            {{
                                              sale_price =  valuProvider.type == 'percentage'
                                                ? ((Number(variant.original_price) * valuProvider.rate) / 100 +
                                                    Number(variant.original_price))
                                                : variant.original_price + valuProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                        <span class="text-success m-0 text-nowrap">
                                          عمولة المنصه:
                                          <span v-if="variant.original_price != ''">
                                            {{
                                              valuProvider.type == 'percentage'
                                                ? (Number(variant.original_price) * valuProvider.rate) / 100
                                                : valuProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                      </div>
                                    
                                      
                                     <!-- Scrolling Container -->
                                     <div
                                            v-for="(items, index) in InstallmentPlans"
                                            :key="index + 'installment_data_plans_forsa'" >
                                          <div
                                            class="scrolling-container"
                                          style="display: flex; overflow-x: auto; gap: 10px; padding: 10px 0;"
                                            v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                            v-if="key == 'forsa'"
                                          >
                                        <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_forsa'"
                                          class="installment-plan-item mb-0"
                                        style="flex: 0 0 auto; white-space: nowrap;">
                                            <!-- Installment Plan Card -->
                                            <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                              <!-- Months -->
                                              <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                              {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                              <!-- Installment Price -->
                                              <p class="p-0 m-0 text-center card-font">
                                                <span class="show-product-used battery-status">
                                                  <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                </span>
                                              </p>
                                            </b-card>
                                          </div>
                                          </div>
                                        </div>

                                      </div>
                                  </div>
                                </b-form-radio>
                            </b-card>


                      </b-col>


                      <b-col md="12"  class="mt-3" v-if="visaProvider" >
                              <b-card >
                                  <!-- Option 1: Bank Installment -->
                                  <b-form-radio :value="visaProvider.PaymentProvider.id" class="w-100 d-flex align-items-center">
                                    <div class="d-flex justify-content-between w-100 align-items-center">
                                      <div class="d-flex align-items-left">
                                        <img  src="@/assets/images/icons/credit_card_icon.svg" alt="Company Icon" class="icon">
                                        <span class="mr-1 text-secondary">بطاقه ائتمان</span>
                                      </div>
                                      <div class="font-small-2">
                                        <div class="price-info">
                                        <span class="m-0 text-nowrap">سعر البائع: {{ variant.original_price }} EGP</span>
                                        <span class="m-0 text-nowrap">
                                          سعر المنصه:
                                          <span v-if="variant.original_price != ''">
                                            {{
                                              sale_price = valuProvider.type == 'percentage'
                                                ? ((Number(variant.original_price) * valuProvider.rate) / 100 +
                                                    Number(variant.original_price))
                                                : variant.original_price + valuProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                        <span class="text-success m-0 text-nowrap">
                                          عمولة المنصه:
                                          <span v-if="variant.original_price != ''">
                                            {{
                                              valuProvider.type == 'percentage'
                                                ? (Number(variant.original_price) * valuProvider.rate) / 100
                                                : valuProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                      </div>
                                     
                                       <!-- Scrolling Container -->
                                       <div
                                            v-for="(items, index) in InstallmentPlans"
                                            :key="index + 'installment_data_plans_visa'" >
                                          <div
                                            class="scrolling-container"
                                          style="display: flex; overflow-x: auto; gap: 10px; padding: 10px 0;"
                                            v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                            v-if="key == 'visa'"
                                          >
                                        <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_visa'"
                                          class="installment-plan-item mb-0"
                                        style="flex: 0 0 auto; white-space: nowrap;">
                                            <!-- Installment Plan Card -->
                                            <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                              <!-- Months -->
                                              <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                              {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                              <!-- Installment Price -->
                                              <p class="p-0 m-0 text-center card-font">
                                                <span class="show-product-used battery-status">
                                                  <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                </span>
                                              </p>
                                            </b-card>
                                          </div>
                                          </div>
                                        </div>
                                        
                                      </div>
                                    </div>
                                  </b-form-radio>
                              </b-card>
                            <!-- Add more options similarly -->
                      </b-col>

                    </b-form-radio-group>

                    <b-form-invalid-feedback
                          :state="
                            errors.length > 0 ? false : null
                          ">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                    </validation-provider>
                  
                   <b-col md="12" v-else>
                      <div >
                        <p class="text-danger text-center">لا تتوفر طريقة دفع  للسعر المحدد.</p>
                      </div>
                   </b-col>

                    </b-row>
            </b-form>
          </validation-observer>
        </b-modal>
      </b-card>
    </validation-observer>

    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, name, index) in errors_back" :key="index">
          <li v-for="(value, valIndex) in values" :key="valIndex">
            {{ name }} {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>
<script>
  import flatPickr from "vue-flatpickr-component";
  import { required } from "@validations";
  import { heightTransition } from "@core/mixins/ui/transition";
  import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
  import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
  import { VueEditor } from "vue2-editor";
  export default {
    components: {
      VueEditor,
      flatPickr,
      AppCollapse,
      AppCollapseItem,
    },
    mixins: [heightTransition],
    data() {
      return {
        errors_back2: [],
        showDismissibleAlert2: false,
        required,
        errors_back: [],
        showDismissibleAlert: false,
        vendorCommissionRate: null,
        itemData: null,
        id: 0,
        offerTypeSelect: {
          text: "",
          value: "",
        },
        offerTypeOptions: [
          {
            text: "Fixed Price",
            value: "fixed_price",
          },
          {
            text: "Percentage",
            value: "percentage",
          },
        ],
        localeOptions: [
          {
            value: "en",
            text: "English",
          },
          {
            value: "ar",
            text: "Arabic",
          },
        ],
        tranLocaleSelect: [],
        itemTrans_name: null,
        variantData: null,
        variant: {
          is_portal: null,
          offer: {
            amount: null,
            enddate: null,
            startdate: null,
            type: null,
            usernumbers: null,
          },
          original_price: "",
          salePrice: null,
          amountPriceDis: true,
          offerHide: false,
        },

        data: null,
        updatePriceUrl: null,
        sendData: null,
        PriceRates: [],
        InstallmentPlans: []
      };
    },
    computed: {
      // Ensure `variant.original_price` is reactive
      
      variantPrice() {
        if(!this.variant.is_portal){
          return Number(this.variant.original_price);
        }
      },

      valuProvider() {
        const provider = this.PriceRates.find(item => 
          item.PaymentProvider &&
          item.PaymentProvider.code == "valu" &&
          item.service_type == "new" && 
          item.from <= this.variantPrice &&
          item.to >= this.variantPrice

        );
        return provider  ? provider : null;
      },

      
      bankInstallmentProvider() {
        const provider = this.PriceRates.find(item => 
          item.PaymentProvider &&
          item.PaymentProvider.code == "bank-installment" &&
          item.service_type == "new" && 
          item.from <= this.variantPrice &&
          item.to >= this.variantPrice
        );
        return provider ? provider : null;
      },

      contactProvider() {
        const provider = this.PriceRates.find(item => 
          item.PaymentProvider &&
          item.PaymentProvider.code == "contact" &&
          item.service_type == "new" && 
          item.from <= this.variantPrice &&
          item.to >= this.variantPrice
        );
        return provider ? provider : null;
      },

      amanProvider() {
        const provider = this.PriceRates.find(item => 
          item.PaymentProvider &&
          item.PaymentProvider.code == "aman" &&
          item.service_type == "new" && 
          item.from <= this.variantPrice &&
          item.to >= this.variantPrice
        );
        return provider ? provider : null;
      },

      shoolaProvider() {
        const provider = this.PriceRates.find(item => 
          item.PaymentProvider &&
          item.PaymentProvider.code == "souhoola" &&
          item.service_type == "new" && 
          item.from <= this.variantPrice &&
          item.to >= this.variantPrice
        );
        return provider ? provider : null;
      },

      forsaProvider() {
        const provider = this.PriceRates.find(item => 
          item.PaymentProvider &&
          item.PaymentProvider.code == "forsa" &&
          item.service_type == "new" && 
          item.from <= this.variantPrice &&
          item.to >= this.variantPrice
        );
        return provider ? provider : null;
      },

      visaProvider() {
        const provider = this.PriceRates.find(item => 
          item.PaymentProvider &&
          item.PaymentProvider.code == "visa" &&
          item.service_type == "new" && 
          item.from <= this.variantPrice &&
          item.to >= this.variantPrice
        );
        return provider ? provider : null;
      },


      // New computed property to check if any provider is valid
      isAnyProviderValid() {
        return this.valuProvider || this.contactProvider || this.amanProvider ||
              this.shoolaProvider || this.forsaProvider || this.visaProvider || this.bankInstallmentProvider;
      }
    
  },
    async created() {
      this.id = this.$route.params.id;
      await this.getVariantData(this.id);

      if(!this.variant.is_portal){
          this.vendorsPriceRates();
        };

        this.getInstallmentPlans();

    },
    methods: {
      success() {
        this.$refs.simpleRules.validate().then((success) => {
          if (success) {
            this.sendData = { ...this.variant };
            if (
              this.variant.offer.amount == "" ||
              this.variant.offer.amount == null
            ) {
              delete this.sendData.offer;
            }

            if (this.variant.is_portal) {
              this.updatePriceUrl = "variants/" + this.id + "/update";
            } else {
              this.updatePriceUrl = "variants/" + this.id + "/instore/update";
            }
            axios
              .put(this.updatePriceUrl, this.sendData)
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.$router.back(1);
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
      },
      getVariantData(id) {
        axios
          .get("variants/" + id + "/showLocale")
          .then((result) => {
            const data = result.data.data;
            if(!data.product.vendor.can_update_price){

this.$router.back(1);
}
            this.data = data;
            this.variant.is_portal = data.is_portal;
            this.variant.profit = data.profit;

            if (data.is_portal) {
              this.variant.original_price = data.original_price;
            } else {
              this.variant.original_price = data.original_price - data.profit;
            }

            this.variant.salePrice = data.sale_price;
            if (data.offer != null) {
              if (data.offer.type == "percentage") {
                this.offerTypeSelect.value = "percentage";
                this.offerTypeSelect.text = "Percentage";
              } else {
                this.offerTypeSelect.value = "fixed_price";
                this.offerTypeSelect.text = "Fixed Price";
              }
              this.variant.offer.amountPriceDis = false;
              this.variant.offerHide = true;
              this.variant.offer.amount = data.offer.amount;
              this.variant.offer.enddate = data.offer.enddate;
              this.variant.offer.startdate = data.offer.startdate;
              this.variant.offer.type = data.offer.type;
              this.variant.offer.usernumbers = data.offer.usernumbers;
            } else {
              this.variant.offer.amountPriceDis = false;
              this.variant.offerHide = false;
            }

            this.variantData = data;

            // this.vendorCommissionRate = data.product.vendor.commission.rate;
          })
          .catch((err) => {
            this.errors_back.length = 0;
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                });
              } else {
                this.errors_back = err.response.data.data;
              }
              this.showDismissibleAlert = true;
            } else {
              this.errors_back = [];
              this.errors_back.push({
                error: "internal server error",
              });
              this.showDismissibleAlert = true;
            }
          });
      },
      handleOk(bvModalEvt) {
        bvModalEvt.preventDefault();
        this.addPriceRate();
      },
      async addPriceRate() {
        this.showDismissibleAlert2 = false;
        const isValid = await this.$refs.addPrice.validate();
        if (isValid) {
          axios
            .post("products/sale-price/get", {
              original_price: this.variant.original_price,
              product_type: "new",
              item_id: this.data.product.item.id.toString(),
              offer_type:
                this.offerTypeSelect == null
                  ? null
                  : this.offerTypeSelect.value,
              offer_amount:
                this.variant.offer.amount == 0
                  ? null
                  : this.variant.offer.amount,
              vendor_id: this.data.product.vendor.id.toString(),
              is_portal: this.variant.is_portal,
              payment_provider_id: this.variant.payment_provider_id,

            })
            .then((result) => {
              this.variant.offer.amount =
                this.variant.offer.amount == 0
                  ? null
                  : this.variant.offer.amount;
              this.variant.offer.type =
                this.offerTypeSelect == null
                  ? null
                  : this.offerTypeSelect.value;
              this.variant.original_price = this.variant.original_price;
              this.variant.offer.startdate =
                this.variant.offer.startdate == null
                  ? null
                  : this.variant.offer.startdate;
              this.variant.offer.enddate =
                this.variant.offer.enddate == null
                  ? null
                  : this.variant.offer.enddate;
              this.variant.offer.usernumbers =
                this.variant.offer.usernumbers == null
                  ? null
                  : this.variant.offer.usernumbers;
              this.variant.profit = result.data.profit;
              this.variant.salePrice = result.data.sale_price;
              this.$nextTick(() => {
                this.$bvModal.hide("modal-prevent-closing");
              });
            })
            .catch((err) => {
              this.errors_back2.length = 0;
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back2.push({
                    error: err.response.data.data,
                  });
                } else {
                  this.errors_back2 = err.response.data.data;
                }
                this.showDismissibleAlert2 = true;
              } else {
                this.errors_back2 = [];
                this.errors_back2.push({
                  error: "internal server error",
                });
                this.showDismissibleAlert2 = true;
              }
            });
        } else {
        }
      },
      // show offer in variants
      showOffer() {
        const oP = parseFloat(this.variant.original_price);
        const sP = parseFloat(this.varian.salePrice);
        if (
          this.variant.salePrice > 0 &&
          this.variant.salePrice != "" &&
          this.variant.salePrice != null &&
          oP >= sP
        ) {
          this.variant.offerHide = true;
          this.variant.offer.amount =
            this.variant.original_price - this.variant.salePrice;
          this.variant.type = "fixed_price";
        } else {
          this.variant.offerHide = false;
          (this.variant.offer.amount = ""),
            (this.variant.type = ""),
            (this.variant.startdate = "");
          this.variant.enddate = "";
          this.variant.usernumbers = "";
        }
      },
      getInstallmentPlans(){
      axios
        .get('products/installment/plans')
        .then((result) => {
          const data = result.data.data;
          console.log('result.data.data',result.data.data);
          this.InstallmentPlans = data;
        })
        .catch((err) => {
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        });
    },
      // when change amount of offer
      changeAmount() {
        const oP = parseFloat(this.variant.original_price);
        const aP = parseFloat(this.variant.offer.amount);
        if (
          this.variant.offer.amount > 0 &&
          this.variant.offer.amount != "" &&
          this.variant.offer.amount != null &&
          oP > aP
        ) {
          this.variant.offerHide = true;
          let price =
            parseFloat(this.variant.original_price) -
            parseFloat(this.variant.offer.amount);
          let profit = (price * this.vendorCommissionRate) / 100;
          this.variant.salePrice = price + profit;
          this.variant.type = "fixed_price";
        } else {
          (this.variant.offer.amount = ""), (this.variant.type = "");
          this.variant.startdate = "";
          this.variant.enddate = "";
          this.variant.usernumbers = "";
          this.variant.offerHide = false;
          let price = parseFloat(this.variant.original_price);
          let profit = (price * this.vendorCommissionRate) / 100;
          this.variant.salePrice = price + profit;
        }
      },
      // when change original price of vaiants
      changeOP() {
        if (
          this.variant.original_price > 0 &&
          this.variant.vriginal_price != "" &&
          this.variant.original_price != null
        ) {
          this.variant.offer.amountPriceDis = false;
          let price = parseFloat(this.variant.original_price);
          let profit = (price * this.vendorCommissionRate) / 100;
          this.variant.salePrice = price + profit;
          this.variant.type = "fixed_price";
        } else {
          this.variant.offer.amountPriceDis = true;
          (this.variant.offer.amount = ""),
            (this.variant.offer.amount = ""),
            (this.variant.type = ""),
            (this.variant.startdate = "");
          this.variant.enddate = "";
          this.variant.usernumbers = "";
          this.variant.salePrice = "";
          this.variant.offer.amountPriceDis = false;
          let price = parseFloat(this.variant.original_price);
          let profit = (price * this.vendorCommissionRate) / 100;
          this.variant.salePrice = price + profit;
        }
      },
      // translation repeated form functions
      translationrepeateAgain() {
        // this.refheightForm = this.$refs.translationForm
        this.variant.translations.push({
          //  id: this.nextTodoId += this.nextTodoId,
        });
        this.tranLocaleSelect.push({
          //  id: this.nextTodoId += this.nextTodoId,
        });
      },
      translationremoveItem(index) {
        if (this.variant.translations.length <= 1) {
          this.$bvModal.msgBoxConfirm("You need minmum one tab to continue.", {
            title: "Alert",
            size: "sm",
            okVariant: "danger",
            okTitle: "Accept",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          });
        } else {
          this.variant.translations.splice(index, 1);
          this.tranLocaleSelect.splice(index, 1);
        }
      },
      vendorsPriceRates() {
       // request roles index
          axios
            .get('price-rates/index')
            .then((result) => {
              // const data = result.data.data

              // this.PriceRates = []
              this.PriceRates = result.data.data
        
            })
            .catch((err) => {
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        },
        calculateInstallmentPrice(voriginal_price,interestRate ,fees_type,admin_fees, months) {
      // const interest = (voriginal_price * interestRate) / 100;
      // const totalWithInterest = voriginal_price + interest;
      // return Math.round(totalWithInterest / months);

      
      let totalVariantPrice;
      const voriginal_pricenum = Number(voriginal_price);
      const admin_feesnum = Number(admin_fees);
      const interestRatenum = Number(interestRate);
      

        if (fees_type == 'fixed') {
          // If fees are fixed, add admin fees directly to the original price
          totalVariantPrice = voriginal_pricenum + admin_feesnum;
        } else {
          // If fees are percentage-based
          if (admin_feesnum != 0) {
            totalVariantPrice = voriginal_pricenum + (voriginal_pricenum * admin_feesnum) / 100;
          } else {
            totalVariantPrice = voriginal_pricenum; // No admin fees
          }
        }

        // Add interest to the total price
        const totalWithInterest = totalVariantPrice + (totalVariantPrice * interestRatenum) / 100;
        // Calculate installment price
        return Math.round(totalWithInterest / months);

    },
      isString(value) {
        return typeof value === "string" || value instanceof String;
      },
    },
  };
</script>
<style scoped lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  @import "@core/scss/vue/libs/vue-select.scss";
  .repeater-form {
    transition: 0.35s height;
  }


@media (max-width: 992px) and (min-width: 480px) {
  .scrolling-container {
  overflow-x: auto;
  white-space: nowrap;
  text-wrap: nowrap;
  max-width: 600px;
  height: 85px;
  width: 300px;
}
}


@media (max-width:480px) and (min-width: 361px) {
  .scrolling-container {
  overflow-x: auto;
  white-space: nowrap;
  text-wrap: nowrap;
  max-width: 600px;
  height: 80px;
  width: 200px;
}
}

@media (max-width: 360px) {
  .scrolling-container {
  overflow-x: auto;
  white-space: nowrap;
  text-wrap: nowrap;
  max-width: 600px;
  height: 80px;
  width: 180px;
}
}



@media (min-width:992px) {
  .scrolling-container {
  overflow-x: auto;
  white-space: nowrap;
  text-wrap: nowrap;
  max-width: 600px;
  height: 85px;
  width: 600px;
}
}



.change-padding .card-body {
    padding: 1rem !important;
  } 


  
  .price-info {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.price-info span {
  font-size: 15px; 
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .price-info {
    // flex-direction: column; /* Stack spans vertically */
    align-items: flex-start; /* Align text to the left */
  }

  .price-info span {
    font-size: 13px;
    white-space: normal; /* Allow text wrapping */
  }
}

@media (max-width: 576px) {
  .price-info span {
    font-size: 11px;
    margin-bottom: 3px;
  }
}

.installment-plans-card .card-body {
  padding: 10px !important;
}



@media (max-width: 768px) {
.installment-plans-card .card-body {
  padding: 5px !important;
}
}


.bg-sliver {
  background: #fafafa;
}



.scrolling-container::-webkit-scrollbar {
  height: 10px !important;
}

.scrolling-container::-webkit-scrollbar-thumb {
  background-color: rgb(52 58 64/ 30%);
  border-radius: 10px;
}

.installment-plans-card .card-font {
  font-size: 12px;
}

.max-width-42 {
  max-width: 42px;
}
</style>
