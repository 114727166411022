<template>
  <div @click="resultShow = false" v-show="showPage == true">
    <form-wizard
      color="#046D6D"
      :title="null"
      :subtitle="null"
      layout="vertical"
      finish-button-text="حفظ البيانات"
      :back-button-text="$t('Add_Product.Previous')"
      :next-button-text="$t('Add_Product.Next')"
      class="wizard-vertical mb-3"
      @on-complete="formSubmitted"
    >
      <!-- Main Data-->
      <tab-content :before-change="validationForm">
        <validation-observer ref="accountRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                {{ $t("Add_Product.General_Data") }}
              </h5>
            </b-col>
            <!-- Brand -->
            <b-col md="4">
              <validation-provider
                #default="{ errors }"
                name="الماركة"
                rules="required"
              >
                <b-form-group
                  :label="$t('Add_Product.Brand')"
                  label-for="Brand"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="brand"
                    v-model="brandSelect"
                    :state="brandSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="brandOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Item -->
            <b-col md="4">
              <validation-provider
                #default="{ errors }"
                name="الجهاز"
                rules="required"
              >
                <b-form-group
                  :label="$t('Add_Product.Item')"
                  label-for="Item"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="item"
                    v-model="itemSelect"
                    :disabled="brendSe"
                    :state="itemSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="itemOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <tab-content :before-change="validationForm2">
        <validation-observer ref="accountRules2" tag="form">
          <b-row v-if="brandSelect != null && itemSelect != null" class="mb-5">
            <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
            >
              <div class="d-flex align-items-center justify-content-center">
                <b-img
                  :src="itemSelect.cover"
                  height="250px"
                  class="product-img"
                />
              </div>
            </b-col>
            <b-col md="6" class="mt-auto mb-auto">
              <br />
              <!-- Product Name -->
              <h6>
                <span style="color: #046d6d"
                  >{{ $t("Add_Product.Name") }} :
                </span>
                <b-badge variant="primary">{{ itemSelect.name }}</b-badge>
              </h6>
              <br />
              <b-card-text class="item-company mb-0">
                <h6>
                  <span style="color: #046d6d"
                    >{{ $t("Add_Product.Brand") }} :
                  </span>
                  <b-badge variant="primary"> {{ brandSelect.text }} </b-badge>
                </h6>
              </b-card-text>
              <br />
              <b-card-text
                v-if="categorySelect != null"
                class="item-company mb-0"
              >
                <h6>
                  <span style="color: #046d6d"
                    >{{ $t("Add_Product.Category") }} :
                  </span>
                  <b-badge variant="primary">
                    {{ categorySelect.text }}
                  </b-badge>
                </h6>
              </b-card-text>
              <br />
              <h6>
                <span style="color: #046d6d"
                  >{{ $t("Add_Product.Tag") }} :
                </span>
                <b-badge
                  variant="primary"
                  class="ml-3"
                  v-for="(item, index) in tagSelect"
                  :key="index"
                  v-html="item.text"
                ></b-badge>
              </h6>
              <br />
              <!-- Product Short Description -->
              <h6
                v-if="
                  itemSelect.short_description != null &&
                    itemSelect.short_description != ''
                "
              >
                <span style="color: #046d6d">Short Description: </span>
              </h6>
              <b-card-text
                class="ml-3"
                v-html="itemSelect.short_description"
              ></b-card-text>
              <br />
              <!-- Product  Description -->
            </b-col>
          </b-row>
          <b-row>
            <!-- Category -->
            <b-col md="12" v-if="itemSelect != null">
              <h5
                v-if="
                  itemSelect.short_description != null &&
                    itemSelect.short_description != ''
                "
              >
                <span style="color: #046d6d">Description: </span>
              </h5>
              <b-card-text
                class="ml-3"
                v-html="itemSelect.description"
              ></b-card-text>
            </b-col>
            <b-col v-if="$route.params.type == 'Portal'" md="12">
              <b-card>
                <b-row>
                  <!-- Accessories -->
                  <b-col md="6">
                    <validation-provider
                      #default="{ errors }"
                      name="اكسسوارات"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('Add_Product.Accessories')"
                        label-for="Accessories"
                        :state="errors.length > 0 ? false : null"
                      >
                        <v-select
                          multiple
                          id="Accessories"
                          mu
                          v-model="accessoriesSelect"
                          :state="accessoriesSelect === null ? false : true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="accessoriesOptions"
                          :selectable="
                            (option) => !option.value.includes('select_value')
                          "
                          label="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="6">
                    <p>فيديو لعرض الموبايل</p>
                    <b-form-group label="Video (.mp4)" label-for="Video">
                      <input
                        type="file"
                        class="form-control-file"
                        multiple="multiple"
                        name="file"
                        id="file"
                        ref="file"
                        change
                        @change="fileUpload"
                        accept="video/*"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>

            <b-col cols="12" class="mt-5">
              <b-card :title="$t('Add_Product.Attributes_and_Values')">
                <b-row>
                  <b-col cols="12">
                    <div>
                      <!-- Row Loop -->
                      <b-row
                        v-for="(item, index) in productData.attributes"
                        :key="'attr' + index"
                      >
                        <!-- Attrbuites -->
                        <b-col
                          md="2"
                          class="d-flex justify-content-center align-items-center"
                        >
                          <!-- <validation-provider #default="{ errors }" name="attribute" rules="required">
                                                        <b-form-group label="Attribute" label-for="attribute" :state="errors.length > 0 ? false:null">
                                                            <v-select disabled :id="'attributes' + index" v-model="attrSelect[index]" :clearable="false" @input="getValues(index,attrSelect[index].value)" :state="attrSelect[index] === null ? false : true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="attr2Options"
                                                                :selectable="option => ! option.value.includes('select_value')" label="text" />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </b-form-group>
                                                    </validation-provider> -->
                          <p class="text-primary mb-0 font-weight-bold h4">
                            {{ attrSelect[index].text }}
                          </p>
                        </b-col>
                        <b-col v-if="attrSelect[index].is_variant" md="4">
                          <validation-provider
                            #default="{ errors }"
                            name="القيم"
                            rules="required"
                          >
                            <b-form-group
                              :label="$t('Add_Product.Values')"
                              label-for="values"
                              :state="errors.length > 0 ? false : null"
                            >
                              <v-select
                                multiple
                                :id="'values' + index"
                                v-model="valuesSelect[index]"
                                :clearable="false"
                                :state="
                                  valuesSelect[index] === null ? false : true
                                "
                                :dir="
                                  $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                :options="valuesOptions[index]"
                                :selectable="
                                  (option) =>
                                    !option.value.includes('select_value')
                                "
                                label="text"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col v-else md="4">
                          <validation-provider
                            #default="{ errors }"
                            name="القيم"
                            rules="required"
                          >
                            <b-form-group
                              :label="$t('Add_Product.Values')"
                              label-for="values"
                              :state="errors.length > 0 ? false : null"
                            >
                              <v-select
                                :id="'values' + index"
                                v-model="valuesSelect[index]"
                                :clearable="false"
                                :state="
                                  valuesSelect[index] === null ? false : true
                                "
                                :dir="
                                  $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                :options="valuesOptions[index]"
                                :selectable="
                                  (option) =>
                                    !option.value.includes('select_value')
                                "
                                label="text"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <!-- Remove Button -->
                        <!-- <b-col lg="2" md="3" class="mb-50">
                                                                                                                                        <b-button v-if="index > 0" v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" class="mt-0 mt-md-2" @click="taxonomyremoveItem(index)">
                                                                                                                                            <feather-icon icon="XIcon" class="mr-25" />
                                                                                                                                            <span>Remove</span>
                                                                                                                                        </b-button>
                                                                                                                                    </b-col> -->
                        <b-col cols="12">
                          <hr />
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                  <!-- add new button -->
                  <!-- <b-col sm="2">
                                                                                                                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" block @click="taxonomyrepeateAgain">
                                                                                                                                <feather-icon icon="PlusIcon" class="mr-25" />
                                                                                                                                <span> {{$t('Add_Product.Add_New')}}   </span>
                                                                                                                            </b-button>
                                                                                                                        </b-col> -->
                </b-row>
                <b-button
                  v-if="itemSelect != null"
                  class="mt-2"
                  @click="generate"
                  block
                  variant="success"
                >
                  {{
                    $route.params.type == "Portal"
                      ? " أضافة الإسعار والصور"
                      : "أضافة السعر"
                  }}
                </b-button>
              </b-card>
            </b-col>
            <b-col cols="12" v-if="varints.length > 0">
              <b-card :title="$t('Add_Product.Variants')" ref="varintsHeight">
                <b-row>
                  <b-col cols="12">
                    <div>
                      <!-- Row Loop -->
                      <b-row
                        v-for="(item, index) in varints"
                        :key="'variant' + index"
                      >
                        <b-col cols="12">
                          <app-collapse
                            id="faq-payment-qna"
                            type="margin"
                            class="mt-0 mb-0"
                          >
                            <app-collapse-item
                              :key="'variantIndex' + index"
                              :title="item.vname"
                            >
                              <b-row>
                                <b-col cols="12" class="">
                                  <b-row>
                                    <!-- price button -->
                                    <b-col
                                      md="2"
                                      class="d-flex justify-content-center align-items-center"
                                    >
                                      <b-button
                                        variant="warning"
                                        @click="setPrice(item, index)"
                                        v-ripple.400="
                                          'rgba(113, 102, 240, 0.15)'
                                        "
                                        v-b-modal.modal-prevent-closing
                                        >{{
                                          $t("Add_Product.Add_Price")
                                        }}</b-button
                                      >
                                    </b-col>
                                    <!-- varint quantity -->
                                    <b-col
                                      v-if="$route.params.type == 'Portal'"
                                      md="2"
                                      class="d-flex justify-content-center align-items-center"
                                    >
                                      <b-form-group
                                        :label="$t('Add_Product.Quantity')"
                                        label-for="quantity"
                                      >
                                        <validation-provider
                                          #default="{ errors }"
                                          name="الكمية"
                                          rules="required|regex:^[0]*[1-9][0-9]*$"
                                        >
                                          <b-form-input
                                            :id="'quantity' + index"
                                            :disabled="
                                              productData.is_new == 'used'
                                                ? true
                                                : false
                                            "
                                            min="0"
                                            type="number"
                                            v-model="
                                              productData.is_new == 'used'
                                                ? (item.vquantity = 1)
                                                : item.vquantity
                                            "
                                            :state="
                                              errors.length > 0 ? false : null
                                            "
                                          />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </validation-provider>
                                      </b-form-group>
                                    </b-col>
                                    <b-col
                                      md="4"
                                      v-show="
                                        item.salePrice > 0 &&
                                          item.salePrice != null
                                      "
                                    >
                                      <p class="text-dark font-weight-bold">
                                        {{
                                          $route.params.type == "Portal"
                                            ? $t("Add_Product.Original_Price")
                                            : "سعر البائع"
                                        }}
                                        : {{ item.voriginal_price }} جنيه
                                      </p>
                                      <p
                                        class="font-weight-bold"
                                        v-show="
                                          item.offerBDel.amount != 0 &&
                                            item.offerBDel.amount != '' &&
                                            item.offerBDel.amount != null
                                        "
                                      >
                                        الخصم:
                                        <b-badge
                                          varaint="primary"
                                          v-if="
                                            item.offerBDel.type == 'fixed_price'
                                          "
                                          >{{
                                            item.offerBDel.amount
                                          }}
                                          EGP</b-badge
                                        >
                                        <b-badge variant="primary" v-else
                                          >{{ item.offerBDel.amount }}%</b-badge
                                        >
                                      </p>
                                      <p
                                        class="text-dark font-weight-bold"
                                        v-if="$route.params.type == 'Portal'"
                                      >
                                        {{ $t("Product_Index.Platform_Price") }}
                                        : {{ item.salePrice }} جنيه
                                      </p>
                                      <p class="text-dark font-weight-bold">
                                        {{
                                          $route.params.type == "Portal"
                                            ? $t(
                                                "Product_Index.Platform_Profit"
                                              )
                                            : "عمولة الخدمة"
                                        }}
                                        : {{ item.profit }} جنيه
                                      </p>
                                      <p
                                        v-if="$route.params.type != 'Portal'"
                                        class="text-dark font-weight-bold"
                                      >
                                        سعر البيع النهائى :
                                        {{
                                          parseFloat(item.voriginal_price) +
                                            parseFloat(item.profit)
                                        }}
                                        جنيه
                                      </p>
                                    </b-col>

                                    <b-col
                                      v-if="$route.params.type == 'Portal'"
                                      cols="12"
                                    >
                                      <hr />
                                    </b-col>

                                    <!-- warranty -->
                                    <b-col
                                      md="6"
                                      v-if="$route.params.type == 'Portal'"
                                    >
                                      <validation-provider
                                        #default="{ errors }"
                                        name="Warranty"
                                        rules="required"
                                      >
                                        <b-form-group
                                          label="Warranty"
                                          label-for="warranty"
                                          :state="
                                            errors.length > 0 ? false : null
                                          "
                                        >
                                          <v-select
                                            id="warranty"
                                            v-model="warrantySelect[index]"
                                            :state="
                                              warrantySelect[index] === null
                                                ? false
                                                : true
                                            "
                                            :dir="
                                              $store.state.appConfig.isRTL
                                                ? 'rtl'
                                                : 'ltr'
                                            "
                                            :options="warrantyOptions"
                                            :selectable="
                                              (option) =>
                                                !option.value.includes(
                                                  'select_value'
                                                )
                                            "
                                            label="text"
                                          />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </b-form-group>
                                      </validation-provider>
                                    </b-col>
                                    <b-col
                                      v-if="$route.params.type == 'Portal'"
                                      cols="12"
                                    >
                                      <hr />
                                    </b-col>

                                    <b-col
                                      v-if="$route.params.type == 'Portal'"
                                      cols="12"
                                    >
                                      <b-row>
                                        <b-col>
                                          <b-row>
                                            <b-col cols="12">
                                              <div>
                                                <b-form
                                                  ref="vImagesform"
                                                  :style="{ height: trHeight }"
                                                  class="repeater-form"
                                                  @submit.prevent="
                                                    vImagesRepeateAgain(item)
                                                  "
                                                >
                                                  <!-- Row Loop -->
                                                  <b-row
                                                    v-for="(im,
                                                    ind) in item.vimages2"
                                                    :id="im + ind"
                                                    :key="im + ind"
                                                    ref="row"
                                                  >
                                                    <!-- Image Reapeted -->
                                                    <b-col
                                                      md="4"
                                                      class="mt-auto mb-auto"
                                                    >
                                                      <b-form-group
                                                        :label="
                                                          $t(
                                                            'Add_Product.Image'
                                                          )
                                                        "
                                                        label-for="image"
                                                      >
                                                        <b-form-file
                                                          @change="
                                                            uploadImage3(
                                                              $event,
                                                              item,
                                                              ind
                                                            )
                                                          "
                                                          :id="
                                                            'image' +
                                                              ind +
                                                              index +
                                                              ind
                                                          "
                                                          v-model="
                                                            item.vimages3[ind]
                                                              .vname
                                                          "
                                                          accept="image/*"
                                                        />
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col md="2">
                                                      <b-media
                                                        class="mb-1 mr-auto"
                                                      >
                                                        <b-img
                                                          v-if="
                                                            item.vimages2[ind]
                                                              .vname != null &&
                                                              item.vimages2[ind]
                                                                .vname != ''
                                                          "
                                                          class="img-thumbnail"
                                                          ref="previewEl"
                                                          :src="
                                                            item.vimages2[ind]
                                                              .vname
                                                          "
                                                          height="100px"
                                                        />
                                                      </b-media>
                                                    </b-col>
                                                    <!-- Remove Button -->
                                                    <b-col
                                                      lg="2"
                                                      md="3"
                                                      class="mt-auto mb-auto"
                                                    >
                                                      <b-button
                                                        v-ripple.400="
                                                          'rgba(234, 84, 85, 0.15)'
                                                        "
                                                        v-if="
                                                          item.vimages2.length >
                                                            1
                                                        "
                                                        variant="outline-danger"
                                                        class="mt-0 mt-md-2"
                                                        @click="
                                                          vImagesRemoveItem(
                                                            ind,
                                                            item
                                                          )
                                                        "
                                                      >
                                                        <feather-icon
                                                          icon="XIcon"
                                                          class="mr-25"
                                                        />
                                                        <span>{{
                                                          $t(
                                                            "Add_Product.Remove"
                                                          )
                                                        }}</span>
                                                      </b-button>
                                                    </b-col>
                                                    <b-col cols="12">
                                                      <hr />
                                                    </b-col>
                                                  </b-row>
                                                </b-form>
                                              </div>
                                            </b-col>
                                            <!-- add new button -->
                                            <b-col cols="12">
                                              <b-button
                                                v-ripple.400="
                                                  'rgba(255, 255, 255, 0.15)'
                                                "
                                                style="width: 40%"
                                                variant="outline-primary"
                                                block
                                                @click="
                                                  vImagesRepeateAgain(item)
                                                "
                                              >
                                                <feather-icon
                                                  icon="PlusIcon"
                                                  class="mr-25"
                                                />
                                                <span>{{
                                                  $t(
                                                    "Add_Product.Add_New_Image"
                                                  )
                                                }}</span>
                                              </b-button>
                                            </b-col>
                                          </b-row>
                                        </b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                </b-col>
                                <!-- Remove Button -->
                                <!-- <b-col cols="2" class="mb-50">
                                                                                                                                <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="flat-danger" class=" m-2 border-0" @click="pricesremoveItem(index)">
                                                                                                                                    <feather-icon icon="XIcon" class="mr-25" />
                                                                                                                                    <span>Remove</span>
                                                                                                                                </b-button>
                                                                                                                            </b-col> -->
                                <b-col cols="12">
                                  <hr />
                                </b-col>
                              </b-row>
                            </app-collapse-item>
                          </app-collapse>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
                <!-- add Price Model -->
                <b-modal
                  id="modal-prevent-closing"
                  cancel-variant="outline-secondary"
                  @ok="handleOk"
                  ok-title="أضف"
                  cancel-title="غلق"
                  centered
                  :title="$t('Add_Product.Add_Price')"
                  size="lg"
                >
                  <validation-observer ref="addPrice">
                    <b-form @submit.stop.prevent="addPriceRate">
                      <b-row>
                        <!-- varint price -->
                        <b-col md="6">
                          <b-form-group
                            :label="$t('Add_Product.Price')"
                            label-for="price"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="السعر"
                              rules="required|regex:\b\d+(?:\.\d\d?)?\b"
                            >
                              <b-form-input
                                v-model="variantItem.voriginal_price"
                                min="0"
                                type="number"
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- varint Amount -->
                        <b-col md="6" v-if="$route.params.type == 'Portal'">
                          <b-form-group
                            :label="$t('Add_Product.Amount')"
                            label-for="amount"
                          >
                            <b-form-input
                              min="1"
                              type="number"
                              v-model="variantItem.offerBDel.amount"
                              placeholder="enter amount"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col v-if="variantItem.offerBDel.amount > 0" md="12">
                          <b-row>
                            <b-col>
                              <app-collapse
                                id="faq-payment-qna"
                                type="margin"
                                class="mt-0 mb-0"
                              >
                                <app-collapse-item
                                  :key="variantIndex"
                                  title="Click To Customize Offer"
                                >
                                  <b-row>
                                    <b-col md="6">
                                      <validation-provider
                                        #default="{ errors }"
                                        name="نوع العرض"
                                        rules="required"
                                      >
                                        <b-form-group
                                          :label="$t('Add_Product.Offer_Type')"
                                          label-for="Offer Type"
                                          :state="
                                            errors.length > 0 ? false : null
                                          "
                                        >
                                          <v-select
                                            id="Offer-Type"
                                            v-model="offerTypeSelect"
                                            :state="
                                              offerTypeSelect === null
                                                ? false
                                                : true
                                            "
                                            :dir="
                                              $store.state.appConfig.isRTL
                                                ? 'rtl'
                                                : 'ltr'
                                            "
                                            :options="offerTypeOptions"
                                            :selectable="
                                              (option) =>
                                                !option.value.includes(
                                                  'select_value'
                                                )
                                            "
                                            label="text"
                                          />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </b-form-group>
                                      </validation-provider>
                                    </b-col>
                                    <b-col md="6">
                                      <b-form-group
                                        :label="$t('Add_Product.User_Numbers')"
                                        label-for="User-Numbers"
                                      >
                                        <validation-provider
                                          #default="{ errors }"
                                          name="عدد المستخدمين"
                                          rules="required|regex:^[0]*[1-9][0-9]*$"
                                        >
                                          <b-form-input
                                            min="1"
                                            id="User-Numbers"
                                            type="number"
                                            v-model="
                                              variantItem.offerBDel.usernumbers
                                            "
                                            :state="
                                              errors.length > 0 ? false : null
                                            "
                                            placeholder="عدد المستخدمين"
                                          />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </validation-provider>
                                      </b-form-group>
                                    </b-col>
                                    <b-col md="6">
                                      <validation-provider
                                        #default="{ errors }"
                                        name="تاريخ البدء"
                                        rules="required"
                                      >
                                        <b-form-group
                                          :label="$t('Add_Product.Start_Date')"
                                          label-for="start date"
                                          :state="
                                            errors.length > 0 ? false : null
                                          "
                                        >
                                          <flat-pickr
                                            v-model="
                                              variantItem.offerBDel.startdate
                                            "
                                            class="form-control"
                                            :config="{
                                              enableTime: true,
                                              dateFormat: 'Y-m-d H:i',
                                              minDate: 'today',
                                            }"
                                          />
                                          <b-form-invalid-feedback
                                            :state="
                                              errors.length > 0 ? false : null
                                            "
                                          >
                                            {{ errors[0] }}
                                          </b-form-invalid-feedback>
                                        </b-form-group>
                                      </validation-provider>
                                    </b-col>
                                    <b-col md="6">
                                      <validation-provider
                                        #default="{ errors }"
                                        name="تاريخ الانتهاء"
                                        rules="required"
                                      >
                                        <b-form-group
                                          :label="$t('Add_Product.End_Date')"
                                          label-for="end date"
                                          :state="
                                            errors.length > 0 ? false : null
                                          "
                                        >
                                          <flat-pickr
                                            v-model="
                                              variantItem.offerBDel.enddate
                                            "
                                            :disabled="
                                              variantItem.offerBDel.startdate ==
                                                null ||
                                                variantItem.offerBDel
                                                  .startdate == ''
                                            "
                                            class="form-control"
                                            :config="{
                                              enableTime: true,
                                              dateFormat: 'Y-m-d H:i',
                                              minDate:
                                                variantItem.offerBDel.startdate,
                                            }"
                                          />
                                          <b-form-invalid-feedback
                                            :state="
                                              errors.length > 0 ? false : null
                                            "
                                          >
                                            {{ errors[0] }}
                                          </b-form-invalid-feedback>
                                        </b-form-group>
                                      </validation-provider>
                                    </b-col>
                                  </b-row>
                                </app-collapse-item>
                              </app-collapse>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>

                      <b-row  v-if="$route.params.type == 'In-Store'">
                      <h5 class="text-left m-1"> طريقة الدفع </h5>
                        <validation-provider
                                #default="{ errors }"
                                name="payment Method"
                                rules="required"
                                class="w-100"
                                v-if="isAnyProviderValid"
                              >
                        <b-form-radio-group v-model="variantItem.payment_provider_id" name="paymentOption" class="w-100">
                        <b-col md="12"   v-if="bankInstallmentProvider" >
                              <b-card >
                                  <!-- Option 1: Bank Installment -->
                                  <b-form-radio :value="bankInstallmentProvider.PaymentProvider.id" class="w-100 d-flex align-items-center p-0">
                                    <div class="d-flex justify-content-between w-100 align-items-center">
                                      <div class="d-flex align-items-left">
                                        <img  src="@/assets/images/icons/bank_installment.svg" alt="Bank Installment Icon" class="icon mr-1 max-width-42">
                                        <!-- <span class="mr-1 text-left text-secondary"> تقسيط بنكي</span> -->
                                      </div>
                                      <div class="font-small-2">
                                        <div class="price-info">
                                        <span class="m-0 text-nowrap">سعر البائع: {{ variantItem.voriginal_price }} EGP</span>
                                        <span class="m-0 text-nowrap">
                                          سعر المنصه:
                                          <span v-if="variantItem.voriginal_price != ''">
                                            {{
                                              sale_price =  valuProvider.type == 'percentage'
                                                ? ((Number(variantItem.voriginal_price) * valuProvider.rate) / 100 +
                                                    Number(variantItem.voriginal_price))
                                                : variantItem.voriginal_price + valuProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                        <span class="text-success m-0 text-nowrap">
                                          عمولة المنصه:
                                          <span v-if="variantItem.voriginal_price != ''">
                                            {{
                                              valuProvider.type == 'percentage'
                                                ? (Number(variantItem.voriginal_price) * valuProvider.rate) / 100
                                                : valuProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                      </div>


                                        
                                       <!-- Scrolling Container -->
                                       <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_CIB'">
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px;  padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'CIB'"
                                            >
                                            <div class="pt-1">
                                              <b-img width="50" height="40px" src="@/assets/images/PaymentBanks/cib.svg"></b-img>
                                            </div>
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_CIB'"
                                           class="installment-plan-item mb-0"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>

                                          <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_Mashreq'">
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px; padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'Mashreq'"
                                            >
                                            <div class="pt-1">
                                              <b-img width="50" height="40px" src="@/assets/images/PaymentBanks/mashreq.svg"></b-img>

                                            </div>
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_Mashreq'"
                                           class="installment-plan-item mb-0"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>


                                          <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_Nbd'">
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px; padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'Nbd'"
                                            >
                                            <div class="pt-1">
                                              <b-img width="50" height="40px" src="@/assets/images/PaymentBanks/nbd.svg"></b-img>
                                            </div>
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_Nbd'"
                                           class="installment-plan-item mb-0"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>


                                          <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_fab'">
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px; padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'Fab'"
                                            >
                                            <div class="pt-1">
                                              <b-img width="50" height="40px" src="@/assets/images/PaymentBanks/nbd.svg"></b-img>

                                            </div>
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_Fab'"
                                           class="installment-plan-item mb-0"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>

                                          <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_NBK'">
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px; padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'NBK'"
                                            >
                                            <div class="pt-1">
                                              <b-img width="50" height="40px" src="@/assets/images/PaymentBanks/nbk.svg"></b-img>

                                            </div>
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_NBK'"
                                           class="installment-plan-item mb-0"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>
                                    


                                      </div>
                                    </div>
                                  </b-form-radio>
                              </b-card>
                            <!-- Add more options similarly -->
                        
                      </b-col>
                        <b-col md="12">
                       
                            <h5 class="text-left m-1"> تقسيط الشركات </h5>
                              <b-card   class="change-padding p-0"  v-if="valuProvider">
                                <!-- Option 1: Bank Installment -->
                                <b-form-radio :value="valuProvider.PaymentProvider.id" class="w-100 d-flex align-items-center p-0">
                                  <div class="d-flex justify-content-between w-100 align-items-center ">
                                    <div class="d-flex align-items-left">
                                      <img  src="@/assets/images/icons/valu_icon.svg" alt="Company Icon" class="icon mr-1 max-width-42">

                                    </div>
                                    <div class="font-small-2">
                                      <div class="price-info">
                                        <span class="m-0 text-nowrap">سعر البائع: {{ variantItem.voriginal_price }} EGP</span>
                                        <span class="m-0 text-nowrap">
                                          سعر المنصه:
                                          <span v-if="variantItem.voriginal_price != ''">
                                            {{
                                              sale_price = valuProvider.type == 'percentage'
                                                ? ((Number(variantItem.voriginal_price) * valuProvider.rate) / 100 +
                                                    Number(variantItem.voriginal_price))
                                                : variantItem.voriginal_price + valuProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                        <span class="text-success m-0 text-nowrap">
                                          عمولة المنصه:
                                          <span v-if="variantItem.voriginal_price != ''">
                                            {{
                                              valuProvider.type == 'percentage'
                                                ? (Number(variantItem.voriginal_price) * valuProvider.rate) / 100
                                                : valuProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                      </div>
                                     <!-- Scrolling Container -->
                                     <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_valu'"
                                          >
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px;  padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'valu'"
                                            >
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_valu'"
                                           class="installment-plan-item mb-0"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>
                                      </div>
                                  </div>
                                </b-form-radio>
                            </b-card>

                            <b-card  class="change-padding p-0" v-if="contactProvider">
                                <!-- Option 1: Bank Installment -->
                                <b-form-radio :value="contactProvider.PaymentProvider.id" class="w-100 d-flex align-items-center p-0">
                                  <div class="d-flex justify-content-between w-100 align-items-center">
                                    <div class="d-flex align-items-left">
                                      <img  src="@/assets/images/icons/contact_icon.svg" alt="Company Icon" class="icon mr-1 max-width-42">

                                    </div>
                                    <div class="font-small-2">
                                      <div class="price-info">
                                        <span class="m-0 text-nowrap">سعر البائع: {{ variantItem.voriginal_price }} EGP</span>
                                        <span class="m-0 text-nowrap">
                                          سعر المنصه:
                                          <span v-if="variantItem.voriginal_price != ''">
                                            {{
                                              sale_price = valuProvider.type == 'percentage'
                                                ? ((Number(variantItem.voriginal_price) * valuProvider.rate) / 100 +
                                                    Number(variantItem.voriginal_price))
                                                : variantItem.voriginal_price + valuProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                        <span class="text-success m-0 text-nowrap">
                                          عمولة المنصه:
                                          <span v-if="variantItem.voriginal_price != ''">
                                            {{
                                              valuProvider.type == 'percentage'
                                                ? (Number(variantItem.voriginal_price) * valuProvider.rate) / 100
                                                : valuProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                      </div>
                                       <!-- Scrolling Container -->
                                       <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_contact'"
                                          >
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px; padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'contact'"
                                            >
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_contact'"
                                           class="installment-plan-item mb-0"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>
                                    
                                      </div>
                                  </div>
                                </b-form-radio>
                            </b-card>


                            
                            <b-card  class="change-padding p-0" v-if="amanProvider">
                                <!-- Option 1: Bank Installment -->
                                <b-form-radio :value="amanProvider.PaymentProvider.id" class="w-100 d-flex align-items-center p-0">
                                  <div class="d-flex justify-content-between w-100 align-items-center">
                                    <div class="d-flex align-items-left">
                                      <img  src="@/assets/images/icons/aman_icon.svg" alt="Company Icon" class="icon mr-1 max-width-42">

                                    </div>
                                    <div class="font-small-2">
                                      <div class="price-info">
                                        <span class="m-0 text-nowrap">سعر البائع: {{ variantItem.voriginal_price }} EGP</span>
                                        <span class="m-0 text-nowrap">
                                          سعر المنصه:
                                          <span v-if="variantItem.voriginal_price != ''">
                                            {{
                                              sale_price = valuProvider.type == 'percentage'
                                                ? ((Number(variantItem.voriginal_price) * valuProvider.rate) / 100 +
                                                    Number(variantItem.voriginal_price))
                                                : variantItem.voriginal_price + valuProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                        <span class="text-success m-0 text-nowrap">
                                          عمولة المنصه:
                                          <span v-if="variantItem.voriginal_price != ''">
                                            {{
                                              valuProvider.type == 'percentage'
                                                ? (Number(variantItem.voriginal_price) * valuProvider.rate) / 100
                                                : valuProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                      </div>
                                     <!-- Scrolling Container -->
                                     <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_aman'"
                                          >
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px; padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'aman'"
                                            >
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_aman'"
                                           class="installment-plan-item mb-0"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>
                                      </div>
                                  </div>
                                </b-form-radio>
                            </b-card>

                            <b-card class="change-padding p-0" v-if="shoolaProvider">
                                <!-- Option 1: Bank Installment -->
                                <b-form-radio :value="shoolaProvider.PaymentProvider.id" class="w-100 d-flex align-items-center p-0">
                                  <div class="d-flex justify-content-between w-100 align-items-center">
                                    <div class="d-flex align-items-left">
                                      <img  src="@/assets/images/icons/sahoola_icon.svg" alt="Company Icon" class="icon mr-1 max-width-42">

                                    </div>
                                    <div class="font-small-2">
                                      <div class="price-info">
                                        <span class="m-0 text-nowrap">سعر البائع: {{ variantItem.voriginal_price }} EGP</span>
                                        <span class="m-0 text-nowrap">
                                          سعر المنصه:
                                          <span v-if="variantItem.voriginal_price != ''">
                                            {{
                                              sale_price = valuProvider.type == 'percentage'
                                                ? ((Number(variantItem.voriginal_price) * valuProvider.rate) / 100 +
                                                    Number(variantItem.voriginal_price))
                                                : variantItem.voriginal_price + valuProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                        <span class="text-success m-0 text-nowrap">
                                          عمولة المنصه:
                                          <span v-if="variantItem.voriginal_price != ''">
                                            {{
                                              valuProvider.type == 'percentage'
                                                ? (Number(variantItem.voriginal_price) * valuProvider.rate) / 100
                                                : valuProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                      </div>
                                    
                                        <!-- Scrolling Container -->
                                        <div
                                              v-for="(items, index) in InstallmentPlans"
                                              :key="index + 'installment_data_plans_souhoola'" >
                                            <div
                                              class="scrolling-container"
                                            style="display: flex; overflow-x: auto; gap: 10px; padding: 10px 0;"
                                              v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                              v-if="key == 'souhoola'"
                                            >
                                          <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_souhoola'"
                                           class="installment-plan-item mb-0"
                                          style="flex: 0 0 auto; white-space: nowrap;">
                                              <!-- Installment Plan Card -->
                                              <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                                <!-- Months -->
                                                <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                                {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                                <!-- Installment Price -->
                                                <p class="p-0 m-0 text-center card-font">
                                                  <span class="show-product-used battery-status">
                                                    <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                  </span>
                                                </p>
                                              </b-card>
                                            </div>
                                            </div>
                                          </div>


                                      </div>
                                  </div>
                                </b-form-radio>
                            </b-card>

                            <b-card class="change-padding p-0" v-if="forsaProvider">
                                <!-- Option 1: Bank Installment -->
                                <b-form-radio :value="forsaProvider.PaymentProvider.id" class="w-100 d-flex align-items-center p-0">
                                  <div class="d-flex justify-content-between w-100 align-items-center">
                                    <div class="d-flex align-items-left">
                                      <img  src="@/assets/images/icons/forsa_icon.svg" alt="Company Icon" class="icon mr-1 max-width-42">

                                    </div>
                                    <div class="font-small-2">
                                      <div class="price-info">
                                        <span class="m-0 text-nowrap">سعر البائع: {{ variantItem.voriginal_price }} EGP</span>
                                        <span class="m-0 text-nowrap">
                                          سعر المنصه:
                                          <span v-if="variantItem.voriginal_price != ''">
                                            {{
                                              sale_price = valuProvider.type == 'percentage'
                                                ? ((Number(variantItem.voriginal_price) * valuProvider.rate) / 100 +
                                                    Number(variantItem.voriginal_price))
                                                : variantItem.voriginal_price + valuProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                        <span class="text-success m-0 text-nowrap">
                                          عمولة المنصه:
                                          <span v-if="variantItem.voriginal_price != ''">
                                            {{
                                              valuProvider.type == 'percentage'
                                                ? (Number(variantItem.voriginal_price) * valuProvider.rate) / 100
                                                : valuProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                      </div>
                                    
                                      
                                     <!-- Scrolling Container -->
                                     <div
                                            v-for="(items, index) in InstallmentPlans"
                                            :key="index + 'installment_data_plans_forsa'" >
                                          <div
                                            class="scrolling-container"
                                          style="display: flex; overflow-x: auto; gap: 10px; padding: 10px 0;"
                                            v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                            v-if="key == 'forsa'"
                                          >
                                        <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_forsa'"
                                          class="installment-plan-item mb-0"
                                        style="flex: 0 0 auto; white-space: nowrap;">
                                            <!-- Installment Plan Card -->
                                            <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                              <!-- Months -->
                                              <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                              {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                              <!-- Installment Price -->
                                              <p class="p-0 m-0 text-center card-font">
                                                <span class="show-product-used battery-status">
                                                  <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                </span>
                                              </p>
                                            </b-card>
                                          </div>
                                          </div>
                                        </div>

                                      </div>
                                  </div>
                                </b-form-radio>
                            </b-card>


                      </b-col>


                      <b-col md="12"  class="mt-3" v-if="visaProvider" >
                              <b-card >
                                  <!-- Option 1: Bank Installment -->
                                  <b-form-radio :value="visaProvider.PaymentProvider.id" class="w-100 d-flex align-items-center">
                                    <div class="d-flex justify-content-between w-100 align-items-center">
                                      <div class="d-flex align-items-left">
                                        <img  src="@/assets/images/icons/credit_card_icon.svg" alt="Company Icon" class="icon">
                                        <span class="mr-1 text-secondary">بطاقه ائتمان</span>
                                      </div>
                                      <div class="font-small-2">
                                        <div class="price-info">
                                        <span class="m-0 text-nowrap">سعر البائع: {{ variantItem.voriginal_price }} EGP</span>
                                        <span class="m-0 text-nowrap">
                                          سعر المنصه:
                                          <span v-if="variantItem.voriginal_price != ''">
                                            {{
                                              sale_price =  valuProvider.type == 'percentage'
                                                ? ((Number(variantItem.voriginal_price) * valuProvider.rate) / 100 +
                                                    Number(variantItem.voriginal_price))
                                                : variantItem.voriginal_price + valuProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                        <span class="text-success m-0 text-nowrap">
                                          عمولة المنصه:
                                          <span v-if="variantItem.voriginal_price != ''">
                                            {{
                                              valuProvider.type == 'percentage'
                                                ? (Number(variantItem.voriginal_price) * valuProvider.rate) / 100
                                                : valuProvider.rate
                                            }}
                                            EGP
                                          </span>
                                        </span>
                                      </div>
                                     
                                       <!-- Scrolling Container -->
                                       <div
                                            v-for="(items, index) in InstallmentPlans"
                                            :key="index + 'installment_data_plans_visa'" >
                                          <div
                                            class="scrolling-container"
                                          style="display: flex; overflow-x: auto; gap: 10px; padding: 10px 0;"
                                            v-for="(item_provider, key) in items" :key="key + 'installment_data'"
                                            v-if="key == 'visa'"
                                          >
                                        <div  v-for="(item, index) in item_provider" :key="index + 'installment_data_index_visa'"
                                          class="installment-plan-item mb-0"
                                        style="flex: 0 0 auto; white-space: nowrap;">
                                            <!-- Installment Plan Card -->
                                            <b-card class="installment-plans-card bg-sliver mb-0 p-0">
                                              <!-- Months -->
                                              <p class="p-0 m-0 text-center card-font">{{ item.months }} شهر</p>
                                              {{ calculateInstallmentPrice(sale_price, item.interest_rate,item.fees_type,item.admin_fees, item.months) }} EGP

                                              <!-- Installment Price -->
                                              <p class="p-0 m-0 text-center card-font">
                                                <span class="show-product-used battery-status">
                                                  <!-- {{ numberWithCommas(item.installment_price) }} pound -->
                                                </span>
                                              </p>
                                            </b-card>
                                          </div>
                                          </div>
                                        </div>
                                        
                                      </div>
                                    </div>
                                  </b-form-radio>
                              </b-card>
                            <!-- Add more options similarly -->
                      </b-col>

                    </b-form-radio-group>

                    <b-form-invalid-feedback
                          :state="
                            errors.length > 0 ? false : null
                          ">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                    </validation-provider>
                  
                   <b-col md="12" v-else>
                      <div >
                        <p class="text-danger text-center">لا تتوفر طريقة دفع  للسعر المحدد.</p>
                      </div>
                   </b-col>

                    </b-row>
                    </b-form>
                  </validation-observer>
                  <!-- error handelr -->
                  <b-alert
                    v-height-fade.appear
                    fade
                    :show="showDismissibleAlert2"
                    @dismissed="showDismissibleAlert2 = false"
                    variant="danger"
                  >
                    <h4 class="alert-heading">Alert</h4>
                    <div class="alert-body">
                      <ul v-for="(values, index) in errors_back2" :key="index">
                        <li v-for="(value, valIndex) in values" :key="valIndex">
                          {{ value }}
                        </li>
                      </ul>
                    </div>
                  </b-alert>
                </b-modal>
              </b-card>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, valIndex) in values" :key="valIndex">
            {{ index }} {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>
<script>
  import { FormWizard, TabContent } from "vue-form-wizard";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import "vue-form-wizard/dist/vue-form-wizard.min.css";
  import { codeIcon } from "./code2";
  import { required } from "@validations";
  import flatPickr from "vue-flatpickr-component";
  // eslint-disable-next-line import/no-extraneous-dependencies
  import "cleave.js/dist/addons/cleave-phone.us";
  import { heightTransition } from "@core/mixins/ui/transition";
  export default {
    components: {
      FormWizard,
      TabContent,
      // eslint-disable-next-line vue/no-unused-components
      ToastificationContent,
      flatPickr,
      Cleave,
    },

    mixins: [heightTransition],
    watch: {
      brandSelect: function() {
        this.brendSe = true;
        this.itemSelect = null;
        if (this.brandSelect != null) {
          this.getItems();
        }
      },
      itemSelect: function() {
        if (this.itemSelect != null) {
          this.getAttrValue();
        }
      },
      attrSelect: function() {
        this.getOptions();
      },
    },
    data() {
      return {
        vendorOptions: [],

        errors_back: [],
        showDismissibleAlert: false,
        errors_back2: [],
        showDismissibleAlert2: false,
        required,
        itemSe: true,
        subActive: true,
        itemSe: true,
        offerTypeSelect: null,
        offerTypeOptions: [
          {
            text: "Fixed Price",
            value: "fixed_price",
          },
          {
            text: "Percentage",
            value: "percentage",
          },
        ],
        codeIcon,
        noResult: false,
        resultShow: false,
        productData: {
          is_new: "new",
          vendor_id: "",
          category_id: "",
          brand_id: "",

          item_id: "",
          tags: [],
          status: "",

          translations: [
            {
              locale: "",
              name: "",
              short_description: "",
              description: "",
            },
            {
              locale: "",
              name: "",
              short_description: "",
              description: "",
            },
          ],
          attributes: [],
          variants: [],
        },
        currentPage: 0,
        serialsNumber: [],
        brandSelect: null,
        brandOptions: [],
        itemSelect: null,
        itemOptions: [],
        brendSe: true,
        categorySelect: null,
        categoryOptions: [],
        tagSelect: [],
        tagOptions: [],
        accessoriesSelect: null,
        accessoriesOptions: [
          {
            value: "select_value",
            text: "Select Value",
          },
        ],
        valuesSelect: [],
        valuesOptions: [
          [
            {
              value: "select_value",
              text: "Select Value",
            },
          ],
        ],
        valuesID: [],
        combTextArr: [[]],
        combValuesArr: [[]],
        varints: [],
        attrSelect: [],
        attrOptions: [],
        attr2Options: [],
        files: null,
        warrantyOptions: [
          {
            text: "محلي",
            value: "locale",
          },
          {
            text: "دولي",
            value: "international",
          },
        ],
        warrantyDurationSelect: [],
        warrantyDurationOptions: [
          {
            text: "7 يوم",
            value: "7",
          },
          {
            text: "14 يوم",
            value: "14",
          },
          {
            text: "30 يوم",
            value: "30",
          },
        ],
        warrantySelect: [],
        variantItem: {
          offerHide: false,
          offerBDel: {
            type: null,
            amount: "",
            startdate: null,
            enddate: null,
            usernumbers: null,
          },
          voriginal_price: "",
        },
        showPage:false,
        userData: null,
        PriceRates: [],
        InstallmentPlans: []


      };
    },
  computed: {
      // Ensure `variantItem.voriginal_price` is reactive
      
      variantPrice() {
        console.log('this.variantItem.voriginal_price',this.variantItem.voriginal_price);
        if(this.$route.params.type == 'In-Store'){
          return Number(this.variantItem.voriginal_price);
        }
      },

      valuProvider() {
        const provider = this.PriceRates.find(item => 
          item.PaymentProvider &&
          item.PaymentProvider.code == "valu" &&
          item.service_type == 'new' && 
          item.from <= this.variantPrice &&
          item.to >= this.variantPrice

        );
        return provider  ? provider : null;
      },

      
      bankInstallmentProvider() {
        const provider = this.PriceRates.find(item => 
          item.PaymentProvider &&
          item.PaymentProvider.code == "bank-installment" &&
          item.service_type == 'new' && 
          item.from <= this.variantPrice &&
          item.to >= this.variantPrice
        );
        return provider ? provider : null;
      },

      contactProvider() {
        const provider = this.PriceRates.find(item => 
          item.PaymentProvider &&
          item.PaymentProvider.code == "contact" &&
          item.service_type == 'new' && 
          item.from <= this.variantPrice &&
          item.to >= this.variantPrice
        );
        return provider ? provider : null;
      },

      amanProvider() {
        const provider = this.PriceRates.find(item => 
          item.PaymentProvider &&
          item.PaymentProvider.code == "aman" &&
          item.service_type == 'new' && 
          item.from <= this.variantPrice &&
          item.to >= this.variantPrice
        );
        return provider ? provider : null;
      },

      shoolaProvider() {
        const provider = this.PriceRates.find(item => 
          item.PaymentProvider &&
          item.PaymentProvider.code == "souhoola" &&
          item.service_type == 'new' && 
          item.from <= this.variantPrice &&
          item.to >= this.variantPrice
        );
        return provider ? provider : null;
      },

      forsaProvider() {
        const provider = this.PriceRates.find(item => 
          item.PaymentProvider &&
          item.PaymentProvider.code == "forsa" &&
          item.service_type == 'new' && 
          item.from <= this.variantPrice &&
          item.to >= this.variantPrice
        );
        return provider ? provider : null;
      },

      visaProvider() {
        const provider = this.PriceRates.find(item => 
          item.PaymentProvider &&
          item.PaymentProvider.code == "visa" &&
          item.service_type == 'new' && 
          item.from <= this.variantPrice &&
          item.to >= this.variantPrice
        );
        return provider ? provider : null;
      },


      // New computed property to check if any provider is valid
      isAnyProviderValid() {
        return this.valuProvider || this.contactProvider || this.amanProvider ||
              this.shoolaProvider || this.forsaProvider || this.visaProvider || this.bankInstallmentProvider;
      }
    
  },

    methods: {
      formSubmitted() {

      for (let off in this.varints) {
        if (
          this.varints[off].voriginal_price == null ||
          this.varints[off].voriginal_price == ''
        ) {

          this.errors_back = [];
          this.errors_back.push({
              error: "Price field is required",
            });
          this.showDismissibleAlert = true;
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'خطأ! ( التحقق من سعر المنتج )',
            showConfirmButton: false,
            timer: 1500,
          })
          return
        }
      }

        this.showDismissibleAlert = false;
        this.productData.item_id = this.itemSelect.value;
        // this.productData.status = this.statusSelect.value

        for (let index in this.warrantySelect) {
          this.varints[index].warranty.type = this.warrantySelect[index].value;
          //   // if (this.varints[index].warranty.type == "seller") {
          //   //   this.varints[index].warranty.duration =
          //   //     this.warrantyDurationSelect[index].value;
          //   // }
        }
        this.productData.accessories = [];
        for (let accessory in this.accessoriesSelect) {
          this.productData.accessories.push(
            this.accessoriesSelect[accessory].value
          );
        }
        for (let vari in this.varints) {
          const datav = this.varints[vari].vtranslations;
          for (let tran in datav) {
            datav[tran].vlocale = datav[tran].vlocale1.value;
          }
        }
        for (let vari in this.varints) {
          const varint = this.varints[vari];
          // varint.vimages = varint.vimages2
          for (let img in varint.vimages2) {
            if (this.isString(varint.vimages2[img].vname)) {
              if (varint.vimages2[img].vname.includes(",")) {
                varint.vimages[img].vname = null;
                var imageC = varint.vimages2[img].vname
                  .split(",")
                  .map((item) => item.trim());
                varint.vimages[img].vname = imageC[1];
              }
            }
          }
        }
        for (let vari in this.varints) {
          const varint = this.varints[vari];
          if (varint.values.includes("-")) {
            varint.values = varint.values.split("-").map((item) => item.trim());
          }
        }
        for (let off in this.varints) {
          if (this.varints[off].offerBDel.amount == "") {
            delete this.varints[off].offer;
          } else {
            this.varints[off].offer.amount = this.varints[off].offerBDel.amount;
            this.varints[off].offer.type = this.varints[off].offerBDel.type;
            this.varints[off].offer.startdate = this.varints[
              off
            ].offerBDel.startdate;
            this.varints[off].offer.enddate = this.varints[
              off
            ].offerBDel.enddate;
            this.varints[off].offer.usernumbers = this.varints[
              off
            ].offerBDel.usernumbers;
          }
        }
        this.productData.variants = this.varints;
        const Product_data = this.productData;
        // for (let vari in Product_data.variants) {
        //     const variant = Product_data.variants[vari]
        //     delete variant.vname
        //     // for (let varia in variant.vtranslations){
        //     //     delete  variant.vtranslations[varia].vlocale1
        //     // }
        // }
        let formData = new FormData();
        if (this.files != null && this.$route.params.type == "Portal") {
          formData.append("video", this.files);
        }
        formData.append(
          "is_portal",
          this.$route.params.type == "Portal" ? 1 : 0
        );
        if (this.$route.params.type == "Portal") {
          for (var i = 0; i < Product_data.accessories.length; i++) {
            formData.append(
              "accessories[" + i + "]",
              Product_data.accessories[i]
            );
          }
        }
        // formData.append("vendor_id", Product_data.vendor_id);
        // formData.append('status'), Product_data.status
        formData.append("item_id", Product_data.item_id);
        formData.append("is_new", Product_data.is_new);

        formData.append("category_id", this.$route.params.category_id);

        // formData.append('status', Product_data.status)
        // formData.append('attributes', Product_data.attributes)
        for (var i = 0; i < Product_data.attributes.length; i++) {
          formData.append(
            "attributes[" + i + "][id]",
            Product_data.attributes[i].id
          );
          for (var z = 0; z < Product_data.attributes[i].values.length; z++) {
            formData.append(
              "attributes[" + i + "][values][" + z + "]",
              Product_data.attributes[i].values[z]
            );
          }
        }
        // formData.append('variants', Product_data.variants)
        for (var i = 0; i < Product_data.variants.length; i++) {
          formData.append(
            "variants[" + i + "][voriginal_price]",
            Product_data.variants[i].voriginal_price
          );
          if(this.$route.params.type == 'In-Store'){
            formData.append(
              'variants[' + i + '][payment_provider_id]',
              Product_data.variants[i].payment_provider_id
            )
          }
         
          formData.append(
            "variants[" + i + "][vquantity]",
            Product_data.variants[i].vquantity
          );
          formData.append(
            "variants[" + i + "][vis_new]",
            Product_data.variants[i].vis_new
          );
          if (this.$route.params.type == "Portal") {
            formData.append(
              "variants[" + i + "][warranty][type]",
              Product_data.variants[i].warranty.type
            );
            if (Product_data.variants[i].warranty.type == "seller") {
              formData.append(
                "variants[" + i + "][warranty][duration]",
                Product_data.variants[i].warranty.duration
              );
            } else if (Product_data.variants[i].warranty.type == "agency") {
              formData.append(
                "variants[" + i + "][warranty][end_date]",
                Product_data.variants[i].warranty.end_date
              );
              formData.append(
                "variants[" + i + "][warranty][agency_file]",
                Product_data.variants[i].warranty.agency_file
              );
            }
          }
          if (this.productData.is_new == "used") {
            formData.append(
              "variants[" + i + "][report_serial_number]",
              Product_data.serialnumber
            );
          }
          if (this.$route.params.type == "Portal") {
            if (Product_data.variants[i].vimages != null) {
              for (
                var z = 0;
                z < Product_data.variants[i].vimages.length;
                z++
              ) {
                if (
                  Product_data.variants[i].vimages[z].vname != "" &&
                  Product_data.variants[i].vimages[z].vname != null
                ) {
                  formData.append(
                    "variants[" + i + "][vimages][" + z + "][vname]",
                    Product_data.variants[i].vimages[z].vname
                  );
                }
              }
            }
          }
          for (var z = 0; z < Product_data.variants[i].values.length; z++) {
            formData.append(
              "variants[" + i + "][values][" + z + "]",
              Product_data.variants[i].values[z]
            );
          }
          // for (var z = 0; z < Product_data.variants[i].vtranslations.length; z++) {
          //     formData.append("variants[" + i + "][vtranslations][" + z + "][vlocale]", Product_data.variants[i].vtranslations[z].vlocale);
          //     formData.append("variants[" + i + "][vtranslations][" + z + "][vshort_description]", Product_data.variants[i].vtranslations[z].vshort_description);
          //     formData.append("variants[" + i + "][vtranslations][" + z + "][vdescription]", Product_data.variants[i].vtranslations[z].vdescription);
          // }
          if (this.$route.params.type == "Portal") {
            if (
              this.varints[i].offerBDel.amount != 0 &&
              this.varints[i].offerBDel.amount != null &&
              this.varints[i].offerBDel.amount != ""
            ) {
              formData.append(
                "variants[" + i + "][offer][amount]",
                Product_data.variants[i].offer.amount
              );
              formData.append(
                "variants[" + i + "][offer][type]",
                Product_data.variants[i].offer.type
              );
              formData.append(
                "variants[" + i + "][offer][startdate]",
                Product_data.variants[i].offer.startdate
              );
              formData.append(
                "variants[" + i + "][offer][enddate]",
                Product_data.variants[i].offer.enddate
              );
              formData.append(
                "variants[" + i + "][offer][usernumbers]",
                Product_data.variants[i].offer.usernumbers
              );
            }
          }
        }
        // for (let [key, value] of formData) {
        // }
        axios
          .post("products", formData)
          .then((result) => {
            this.$swal({
              position: "center",
              icon: "success",
              title: "Your work has been saved",
              showConfirmButton: false,
              timer: 1500,
            });
            if (result.data.data.variants[0].is_new) {
              this.$router.push({
                path:
                  "/New/ShowProduct/variant/show/" +
                  result.data.data.variants[0].id,
              });
            } else {
              this.$router.push({
                path:
                  "/Used/ShowProduct/variant/show/" +
                  result.data.data.variants[0].id,
              });
            }
            // if (this.$route.params.type == "Portal") {
            //   this.$router.push({
            //     path: "/Product/Index",
            //   });
            // } else {
            //   this.$router.push({
            //     path:
            //       "/New/ShowProduct/variant/show/" +
            //       result.data.data.variants[0].id,
            //   });
            // }
          })
          .catch((err) => {
            this.$swal({
              position: "center",
              icon: "error",
              title: "Error!",
              showConfirmButton: false,
              timer: 1500,
            });
            this.errors_back.length = 0;
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                });
              } else {
                this.errors_back = err.response.data.data;
              }
              this.showDismissibleAlert = true;
            } else {
              this.errors_back = [];
              this.errors_back.push({
                error: "internal server error",
              });
              this.showDismissibleAlert = true;
            }
          });
      },
      setPrice(item, index) {
        this.variantItem = item;
        this.variantIndex = index;
      },
      handleOk(bvModalEvt) {
        bvModalEvt.preventDefault();
        this.addPriceRate();
      },
      async addPriceRate() {
        this.showDismissibleAlert2 = false;
        const isValid = await this.$refs.addPrice.validate();
        if (isValid) {
          var sendData = null;
          if (
            this.variantItem.offerBDel.amount == 0 ||
            this.variantItem.offerBDel.amount == null ||
            this.variantItem.offerBDel.amount == ""
          ) {
            sendData = {
              original_price: this.variantItem.voriginal_price,
              item_id: this.itemSelect.value,
              product_type: "new",
              is_portal: this.$route.params.type == "Portal" ? true : false,
              payment_provider_id: this.$route.params.type == "In-Store" ? this.variantItem.payment_provider_id : null,

            };
          } else {
            sendData = {
              original_price: this.variantItem.voriginal_price,
              item_id: this.itemSelect.value,
              product_type: "new",
              offer_type:
                this.offerTypeSelect == null
                  ? null
                  : this.offerTypeSelect.value,
              offer_amount:
                this.variantItem.offerBDel.amount == 0
                  ? null
                  : this.variantItem.offerBDel.amount,
              product_type: "new",
              is_portal: this.$route.params.type == "Portal" ? true : false,
              payment_provider_id: this.$route.params.type == "In-Store" ? this.variantItem.payment_provider_id : null,
            };
          }
          axios
            .post("products/sale-price/get", sendData)
            .then((result) => {
              this.varints[this.variantIndex].offerBDel.amount =
                this.variantItem.offerBDel.amount == 0
                  ? null
                  : this.variantItem.offerBDel.amount;
              this.varints[this.variantIndex].offerBDel.type =
                this.offerTypeSelect == null
                  ? null
                  : this.offerTypeSelect.value;
              this.varints[
                this.variantIndex
              ].voriginal_price = this.variantItem.voriginal_price;
              this.varints[this.variantIndex].offerBDel.startdate =
                this.variantItem.offerBDel.startdate == null
                  ? null
                  : this.variantItem.offerBDel.startdate;
              this.varints[this.variantIndex].offerBDel.enddate =
                this.variantItem.offerBDel.enddate == null
                  ? null
                  : this.variantItem.offerBDel.enddate;
              this.varints[this.variantIndex].offerBDel.usernumbers =
                this.variantItem.offerBDel.usernumbers == null
                  ? null
                  : this.variantItem.offerBDel.usernumbers;
              this.varints[this.variantIndex].profit = result.data.profit;
              this.varints[this.variantIndex].salePrice =
                result.data.sale_price;
              this.$nextTick(() => {
                this.$bvModal.hide("modal-prevent-closing");
              });
            })
            .catch((err) => {
              this.errors_back2.length = 0;
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back2.push({
                    error: err.response.data.data,
                  });
                } else {
                  this.errors_back2 = err.response.data.data;
                }
                this.showDismissibleAlert2 = true;
              } else {
                this.errors_back2 = [];
                this.errors_back2.push({
                  error: "internal server error",
                });
                this.showDismissibleAlert2 = true;
              }
            });
        } else {
        }
      },
      getAttrValue() {
        this.errors_back = [];
        this.showDismissibleAlert = false;
        axios
          .get("products/items/" + this.itemSelect.value + "/valid-data")
          .then((result) => {
            const data = result.data.data.attributes;
            const item = result.data.data.item;
            this.attrOptions = [];
            this.attrSelect = [];
            this.productData.attributes = [];
            this.valuesOptions = [];
            this.valuesSelect = [];
            this.combTextArr = [];
            this.combValuesArr = [];
            this.attr2Options = [];
            this.attr2Options = null;
            if (data.length > 0) {
              if (this.$route.params.type == "Portal") {
                for (let attr in data) {
                  if (data[attr].translation != null) {
                    this.attrOptions.push({
                      value: data[attr].id.toString(),
                      text: data[attr].translation.name,
                      is_variant: data[attr].is_variant,
                    });
                    this.attrSelect.push({
                      value: data[attr].id.toString(),
                      text: data[attr].translation.name,
                      is_variant: data[attr].is_variant,
                    });
                    this.productData.attributes.push({
                      id: "",
                      values: [],
                    });
                    this.valuesOptions.push([]);
                    for (let val in data[attr].values) {
                      this.valuesOptions[attr].push({
                        text: data[attr].values[val].translation.name,
                        value: data[attr].values[val].id.toString(),
                        is_variant: data[attr].is_variant,
                      });
                    }
                    if (
                      data[attr].values.filter((e) => e.valid === true).length >
                        0 ||
                      (data[attr].values.length == 1 &&
                        data[attr].is_variant == false)
                    ) {
                      for (let val in data[attr].values) {
                        this.valuesSelect.push({
                          text: data[attr].values[val].translation.name,
                          value: data[attr].values[val].id.toString(),
                          is_variant: data[attr].is_variant,
                        });
                      }
                    } else {
                      if (data[attr].values.length == 1) {
                        for (let val in data[attr].values) {
                          this.valuesSelect.push([
                            {
                              text: data[attr].values[val].translation.name,
                              value: data[attr].values[val].id.toString(),
                              is_variant: data[attr].is_variant,
                            },
                          ]);
                        }
                      } else {
                        this.valuesSelect.push([]);
                      }
                    }
                  }
                  if (data[attr].is_variant) {
                    this.combTextArr.push([]);
                    this.combValuesArr.push([]);
                  }
                }
              } else {
                for (let attr in data) {
                  if (data[attr].translation != null) {
                    this.attrOptions.push({
                      value: data[attr].id.toString(),
                      text: data[attr].translation.name,
                      slug: data[attr].translation.slug,
                    });
                    this.attrSelect.push({
                      value: data[attr].id.toString(),
                      text: data[attr].translation.name,
                      slug: data[attr].translation.slug,
                    });
                    this.productData.attributes.push({
                      id: "",
                      values: [],
                    });
                    this.valuesOptions.push([]);
                    for (let val in data[attr].values) {
                      this.valuesOptions[attr].push({
                        text: data[attr].values[val].translation.name,
                        value: data[attr].values[val].id.toString(),
                        is_variant: data[attr].is_variant,
                      });
                    }
                    if (
                      data[attr].values.filter((e) => e.valid === true).length >
                        0 ||
                      (data[attr].values.length == 1 &&
                        data[attr].is_variant == false)
                    ) {
                      for (let val in data[attr].values) {
                        this.valuesSelect.push({
                          text: data[attr].values[val].translation.name,
                          value: data[attr].values[val].id.toString(),
                          is_variant: data[attr].is_variant,
                        });
                      }
                    } else {
                      // if (data[attr].values.length == 1) {
                      //   for (let val in data[attr].values) {
                      //     this.valuesSelect.push([
                      //       {
                      //         text: data[attr].values[val].translation.name,
                      //         value: data[attr].values[val].id.toString(),
                      //         is_variant: data[attr].is_variant,
                      //       },
                      //     ]);
                      //   }
                      // } else {
                      this.valuesSelect.push([]);
                      // }
                    }
                  }
                  if (data[attr].is_variant) {
                    this.combTextArr.push([]);
                    this.combValuesArr.push([]);
                  }
                }
              }

              this.attr2Options = this.attrOptions;
              (this.categorySelect = {
                text: item.category.translation.name,
                value: item.category.id.toString(),
              }),
                (this.itemSelect.name = item.item_translations.name),
                (this.itemSelect.description =
                  item.item_translations.description),
                (this.itemSelect.short_description =
                  item.item_translations.short_description),
                (this.itemSelect.status = item.status),
                (this.itemSelect.cover = item.cover);
              this.tagSelect = [];
              for (let indeex in item.tags) {
                this.tagSelect.push({
                  text: item.tags[indeex].name,
                  value: item.tags[indeex].id.toString(),
                });
              }
            }
          })
          .catch((err) => {
            this.errors_back.length = 0;
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                });
              } else {
                this.errors_back = err.response.data.data;
              }
              this.showDismissibleAlert = true;
            } else {
              this.errors_back = [];
              this.errors_back.push({
                error: "internal server error",
              });
              this.showDismissibleAlert = true;
            }
          });
      },
      generate() {
        if (this.$route.params.type == "Portal") {
          for (let val in this.valuesSelect) {
            const vale = this.valuesSelect[val];

            this.productData.attributes[val].values = [];
            if (this.productData.is_new == "used") {
              this.productData.attributes[val].values.push(vale.value);
            } else {
              if (Array.isArray(vale)) {
                for (let valID in vale) {
                  this.productData.attributes[val].values.push(
                    vale[valID].value
                  );
                }
              } else {
                this.productData.attributes[val].values.push(vale.value);
              }
            }
          }

          for (let att in this.productData.attributes) {
            const attrbu = this.productData.attributes[att];
            attrbu.id = this.attrSelect[att].value;
          }
          var mainValues = [];
          for (let val in this.valuesSelect) {
            const vale = this.valuesSelect[val];
            if (Array.isArray(vale)) {
              mainValues.push(vale);
            }
          }
          for (let main in mainValues) {
            const mainVal = mainValues[main];
            this.combTextArr[main].length = 0;
            if (this.productData.is_new == "used") {
              this.combTextArr[main].push(mainVal.text);
            } else {
              if (Array.isArray(mainVal)) {
                for (let valID in mainVal) {
                  this.combTextArr[main].push(mainVal[valID].text);
                }
              }
            }
          }
          for (let main in mainValues) {
            const mainVal = mainValues[main];
            this.combValuesArr[main].length = 0;
            if (this.productData.is_new == "used") {
              this.combValuesArr[main].push(mainVal.value);
            } else {
              if (Array.isArray(mainVal)) {
                for (let valID in mainVal) {
                  this.combValuesArr[main].push(mainVal[valID].value);
                }
              }
            }
          }

          const compainText = this.getCombn(this.combTextArr);
          const compainValues = this.getCombn2(this.combValuesArr);
          for (let tran in this.varints) {
            this.varints[tran].vis_new = this.productData.is_new;
          }
          this.subActive = false;
        } else {
          for (let val in this.valuesSelect) {
            const vale = this.valuesSelect[val];
            this.productData.attributes[val].values = [];

            this.productData.attributes[val].values.push(vale.value);
          }
          for (let att in this.productData.attributes) {
            const attrbu = this.productData.attributes[att];
            attrbu.id = this.attrSelect[att].value;
          }
          for (let val in this.valuesSelect) {
            const vale = this.valuesSelect[val];

            if (vale.is_variant) {
              this.combTextArr[val] = [];
              this.combTextArr[val].push(vale.text);
            }
          }
          for (let val in this.valuesSelect) {
            const vale = this.valuesSelect[val];
            this.combValuesArr[val] = [];

            this.combValuesArr[val].push(vale.value);
          }
          const compainText = this.getCombn(this.combTextArr);
          const compainValues = this.getCombn2(this.combValuesArr);

          for (let tran in this.varints) {
            this.varints[tran].vis_new = this.productData.is_new;
          }
          this.subActive = false;
        }
      },
      // make combination to get names of variants
      getCombn(arr) {
        if (arr.length == 1) {
          var ans = [];
          for (let ind in arr[0]) {
            ans.push(arr[0][ind]);
          }
          this.warrantySelect.length = 0;
          this.warrantyDurationSelect.length = 0;
          this.varints.length = 0;
          for (let inde in ans) {
            this.warrantySelect.push();
            this.warrantyDurationSelect.push();
            this.varints.push({
              vname: ans[inde],
              vquantity: "1",
              vis_new: "new",
              warranty: {
                type: null,
                duration: null,
                end_date: null,
                agency_file: null,
              },
              voriginal_price: "",
              salePrice: "",
              amountPriceDis: true,
              report_serial_number: "",
              vtranslations: [
                {
                  vlocale1: {
                    text: "English",
                    value: "en",
                    disa: true,
                  },
                  vlocale: "",
                  vdescription: "",
                  vshort_description: "",
                },
                {
                  vlocale1: {
                    text: "Arabic",
                    value: "ar",
                    disa: true,
                  },
                  vlocale: "",
                  vdescription: "",
                  vshort_description: "",
                },
              ],
              offerHide: false,
              offerBDel: {
                type: "fixed_price",
                amount: "",
                startdate: "",
                enddate: "",
                usernumbers: "",
              },
              offer: {
                type: "fixed_price",
                amount: "",
                startdate: "",
                enddate: "",
                usernumbers: "",
              },
              vimages: [
                {
                  vname: "",
                },
              ],
              vimages2: [
                {
                  vname: "",
                },
              ],
              vimages3: [
                {
                  vname: [],
                },
              ],
            });
          }
          return ans;
        } else {
          var ans = [];
          // recur with the rest of the array.
          var otherCases = this.getCombn(arr.slice(1));
          for (var i = 0; i < otherCases.length; i++) {
            for (var j = 0; j < arr[0].length; j++) {
              ans.push(arr[0][j] + " - " + otherCases[i]);
            }
          }
          this.warrantySelect.length = 0;
          this.varints.length = 0;
          this.warrantyDurationSelect.length = 0;
          for (let ind in ans) {
            this.warrantySelect.push();
            this.warrantyDurationSelect.push();
            this.varints.push({
              vname: ans[ind],
              vquantity: "1",
              voriginal_price: "",
              salePrice: "",
              vis_new: "new",
              warranty: {
                type: null,
                duration: null,
                end_date: null,
                agency_file: null,
              },
              amountPriceDis: true,
              report_serial_number: "",
              vtranslations: [
                {
                  vlocale1: {
                    text: "English",
                    value: "en",
                    disa: true,
                  },
                  vlocale: "",
                  vdescription: "",
                  vshort_description: "",
                },
                {
                  vlocale1: {
                    text: "Arabic",
                    value: "ar",
                    disa: true,
                  },
                  vlocale: "",
                  vdescription: "",
                  vshort_description: "",
                },
              ],
              offerHide: false,
              offerBDel: {
                type: "fixed_price",
                amount: "",
                startdate: "",
                enddate: "",
                usernumbers: "",
              },
              offer: {
                type: "fixed_price",
                amount: "",
                startdate: "",
                enddate: "",
                usernumbers: "",
              },
              vimages: [
                {
                  vname: "",
                },
              ],
              vimages2: [
                {
                  vname: "",
                },
              ],
              vimages3: [
                {
                  vname: [],
                },
              ],
            });
          }
          return ans;
        }
      },
      // make combinations to get values of variants
      getCombn2(arr) {
        if (arr.length == 1) {
          var ans = [];
          for (let ind in arr[0]) {
            ans.push(arr[0][ind]);
          }
          for (let inde in ans) {
            if(this.varints[inde]){
              this.varints[inde].values = [ans[inde]];
            }
          }
          return ans;
        } else {
          var ans = [];
          // recur with the rest of the array.
          var otherCases = this.getCombn2(arr.slice(1));
          for (var i = 0; i < otherCases.length; i++) {
            for (var j = 0; j < arr[0].length; j++) {
              ans.push(arr[0][j] + "-" + otherCases[i]);
            }
          }
          for (let ind in ans) {
            this.varints[ind].values = ans[ind];
            if (this.$route.params.type == "Portal") {
              for (let valu in this.valuesSelect) {
                if (
                  !Array.isArray(this.valuesSelect[valu]) &&
                  this.valuesSelect[valu].is_variant == false
                ) {
                  this.varints[ind].values +=
                    "-" + this.valuesSelect[valu].value;
                }
              }
            }
          }

          return ans;
        }
      },
      // get values
      getValues(index, id) {
        axios
          .get("products/attribute/" + id + "/get-values")
          .then((result) => {
            this.valuesOptions[index].length = 0;
            this.valuesSelect[index] = null;
            let data = result.data.data;
            for (let ind in data) {
              if (data[ind].translation != null) {
                this.valuesOptions[index].push({
                  value: data[ind].id.toString(),
                  text: data[ind].translation.name,
                });
              }
            }
          })
          .catch((err) => {
            this.errors_back.length = 0;
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                });
              } else {
                this.errors_back = err.response.data.data;
              }
              this.showDismissibleAlert = true;
            } else {
              this.errors_back = [];
              this.errors_back.push({
                error: "internal server error",
              });
              this.showDismissibleAlert = true;
            }
          });
      },
      // uploade video
      fileUpload(e) {
        this.files = e.target.files[0];
      },
      fileUpload2(event, index) {
        this.varints[index].warranty.agency_file = event.target.files[0];
      },
      // set attrbuites
      getOptions() {
        this.attr2Options = this.attrOptions.filter(
          (item) => !this.attrSelect.includes(item)
        );
      },
      getAccessories() {
        axios
          .get("accessories")
          .then((result) => {
            const data = result.data.data;
            for (let index in data) {
              if (data[index].translation != null) {
                this.accessoriesOptions.push({
                  text: data[index].translation.name,
                  value: data[index].id.toString(),
                });
              } else {
                this.accessoriesOptions.push({
                  text: "undefinded",
                  value: data[index].id.toString(),
                });
              }
            }
          })
          .catch((err) => {
            this.errors_back.length = 0;
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                });
              } else {
                this.errors_back = err.response.data.data;
              }
              this.showDismissibleAlert = true;
            } else {
              this.errors_back = [];
              this.errors_back.push({
                error: "internal server error",
              });
              this.showDismissibleAlert = true;
            }
          });
      },
      // upload function vimages of item
      uploadImage3(event, item, index) {
        let files = event.target.files || event.dataTransfer.files;
        if (!files.length) {
          return;
        } else {
          this.createImage3(files[0], index, item);
        }
      },
      createImage3(file, ind, item) {
        let reader = new FileReader();
        reader.onload = (e) => {
          item.vimages2[ind].vname = e.target.result;
        };
        reader.readAsDataURL(file);
      },
      validationForm() {
        return new Promise((resolve, reject) => {
          this.$refs.accountRules.validate().then((success) => {
            if (success) {
              if (this.errors_back.length == 0) {
                resolve(true);
              } else {
                reject();
              }
            } else {
              reject();
            }
          });
        });
      },
      validationForm2() {
        return new Promise((resolve, reject) => {
          this.$refs.accountRules2.validate().then((success) => {
            if (success) {
              resolve(true);
            } else {
              reject();
            }
          });
        });
      },
      getItems() {
        // this.itemSelect = null
        if (this.brandSelect != null) {
          this.itemOptions = [
            {
              value: "select_value",
              text: "Select Value",
            },
          ];
          axios
            .get("products/brands/" + this.brandSelect.value + "/items/get")
            .then((result) => {
              const data = result.data.data;
              this.itemOptions.lenght = 0;
              if (data.length > 0) {
                for (let ind in data) {
                  if (data[ind] != null) {
                    this.itemOptions.push({
                      text: data[ind].name,
                      value: data[ind].id.toString(),
                    });
                  }
                }
              }
              this.brendSe = false;
            })
            .catch((err) => {
              this.errors_back.length = 0;
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  });
                } else {
                  this.errors_back = err.response.data.data;
                }
                this.showDismissibleAlert = true;
              } else {
                this.errors_back = [];
                this.errors_back.push({
                  error: "internal server error",
                });
                this.showDismissibleAlert = true;
              }
            });
        }
      },
      vImagesRepeateAgain(item) {
        //  this.refheightForm = this.$refs.pricesForm
        item.vimages.push({
          vname: "",
        });
        item.vimages2.push({
          vname: "",
        });
        item.vimages3.push({
          vname: [],
        });
      },
      vImagesRemoveItem(index, item) {
        if (item.vimages2.length <= 1) {
          this.$bvModal.msgBoxConfirm("You need minmum one tab to continue.", {
            title: "Alert",
            size: "sm",
            okVariant: "danger",
            okTitle: "Accept",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          });
        } else {
          item.vimages2.splice(index, 1);
          item.vimages3.splice(index, 1);
          item.vimages.splice(index, 1);
        }
      },

      checkCategory(){
        var type = '';
        if(this.$route.params.type == "Portal"){
          type ='is_portal_new';
        }else{
          type ='is_instore_new';
        }
            axios
            .post("/checkCategory/"+ this.$route.params.category_id,{ type: type})
            .then((result) => {
              const data = result.data.data;

                if(data == true){
                  this.showPage = true;
                }else{
                  this.$router.push({
                    path: '/Product/Add' 
                  })
                }
            })
            .catch((err) => {
              this.errors_back.length = 0;
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  });
                } else {
                  this.errors_back = err.response.data.data;
                }
                this.showDismissibleAlert = true;
              } else {
                this.errors_back = [];
                this.errors_back.push({
                  error: "internal server error",
                });
                this.showDismissibleAlert = true;
              }
            });
      },

      
    vendorsPriceRates() {
      // request roles index
      axios
        .get('price-rates/index')
        .then((result) => {
          //console.log(result)
          // const data = result.data.data

          // this.PriceRates = []
          this.PriceRates = result.data.data
     
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    getInstallmentPlans(){
      axios
        .get('products/installment/plans')
        .then((result) => {
          const data = result.data.data;
          // console.log('result.data.data',result.data.data);
          this.InstallmentPlans = data;
        })
        .catch((err) => {
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        });
    },
    numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  calculateInstallmentPrice(voriginal_price,interestRate ,fees_type,admin_fees, months) {
      // const interest = (voriginal_price * interestRate) / 100;
      // const totalWithInterest = voriginal_price + interest;
      // return Math.round(totalWithInterest / months);

      
      let totalVariantPrice;
      const voriginal_pricenum = Number(voriginal_price);
      const admin_feesnum = Number(admin_fees);
      const interestRatenum = Number(interestRate);
      

        if (fees_type == 'fixed') {
          // If fees are fixed, add admin fees directly to the original price
          totalVariantPrice = voriginal_pricenum + admin_feesnum;
        } else {
          // If fees are percentage-based
          if (admin_feesnum != 0) {
            totalVariantPrice = voriginal_pricenum + (voriginal_pricenum * admin_feesnum) / 100;
          } else {
            totalVariantPrice = voriginal_pricenum; // No admin fees
          }
        }

        // Add interest to the total price
        const totalWithInterest = totalVariantPrice + (totalVariantPrice * interestRatenum) / 100;
        // Calculate installment price
        return Math.round(totalWithInterest / months);

    },

      isString(value) {
        return typeof value === "string" || value instanceof String;
      },
    },
    created() {


      this.checkCategory();

      axios
        .get("products/create")
        .then((result) => {
          const data = result.data.data;
          // set Brands
          if (data.brands.length > 0) {
            for (let ind in data.brands) {
              if (data.brands[ind].translation != null) {
                this.brandOptions.push({
                  text: data.brands[ind].translation.name,
                  value: data.brands[ind].id.toString(),
                });
              }
            }
          }
          this.getAccessories();
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });

        if(this.$route.params.type == 'In-Store'){
          if (localStorage.getItem('userData') != null) {
            const decryptedText = this.CryptoJS.AES.decrypt(
              localStorage.getItem("userData"),
              "EncryptDataMobileMasr"
            ).toString(this.CryptoJS.enc.Utf8);

            this.userData = JSON.parse(decryptedText);

          this.vendorsPriceRates();

          }
        }

        this.getInstallmentPlans();
    },
  };
</script>
<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/vue-wizard.scss";
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  #vs9__listbox {
    z-index: 99999 !important;
  }
  [dir="rtl"] .vs__selected-options {
    direction: rtl !important;
  }
  .vs__selected {
    color: black !important;
  }
  .vertical.wizard-vertical.vue-form-wizard .wizard-navigation .wizard-nav {
    min-width: 75px !important;
  }
  .vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn::after {
    content: "\e843" !important;
  }
  .vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn::before {
    content: "\e844" !important;
  }
  @media only screen and (max-width: 425px) {
    .wizard-nav {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      flex-direction: row !important;
    }
  }


  
.icon {
  width: 45px;
  height: 24px;
  margin-left: 10px;
}

p {
  margin-bottom: 0;
}

.text-success {
  color: green;
}


.b-form-radio {
  cursor: pointer;
}


@media (max-width: 992px) and (min-width: 480px) {
  .scrolling-container {
  overflow-x: auto;
  white-space: nowrap;
  text-wrap: nowrap;
  max-width: 600px;
  height: 85px;
  width: 300px;
}
}


@media (max-width:480px) and (min-width: 361px) {
  .scrolling-container {
  overflow-x: auto;
  white-space: nowrap;
  text-wrap: nowrap;
  max-width: 600px;
  height: 80px;
  width: 200px;
}
}

@media (max-width: 360px) {
  .scrolling-container {
  overflow-x: auto;
  white-space: nowrap;
  text-wrap: nowrap;
  max-width: 600px;
  height: 80px;
  width: 180px;
}
}



@media (min-width:992px) {
  .scrolling-container {
  overflow-x: auto;
  white-space: nowrap;
  text-wrap: nowrap;
  max-width: 600px;
  height: 85px;
  width: 600px;
}
}


.change-padding .card-body {
    padding: 1rem !important;
  } 


  .price-info {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.price-info span {
  font-size: 15px; 
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .price-info {
    // flex-direction: column; /* Stack spans vertically */
    align-items: flex-start; /* Align text to the left */
  }

  .price-info span {
    font-size: 13px;
    white-space: normal; /* Allow text wrapping */
  }
}

@media (max-width: 576px) {
  .price-info span {
    font-size: 11px;
    margin-bottom: 3px;
  }
}


.scrolling-container::-webkit-scrollbar {
  height: 10px !important;
}

.scrolling-container::-webkit-scrollbar-thumb {
  background-color: rgb(52 58 64/ 30%);
  border-radius: 10px;
}

.installment-plans-card .card-font {
  font-size: 12px;
}

.installment-plans-card .card-body {
  padding: 10px !important;
}



@media (max-width: 768px) {
.installment-plans-card .card-body {
  padding: 5px !important;
}
}

.bg-sliver {
  background: #fafafa;
}

.p-8 {
  padding: 8px !important;
}

.max-width-42 {
  max-width: 42px;
}
</style>
