<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col md="7" class="h-100 seller-account-image">
        <div class="h-25 text-center d-flex align-items-end">
          <b-img
            :src="require('@/assets/images/logo/03.png')"
            class="mx-auto img-fluid"
            alt="Login V2"
          />
        </div>
        <div class="h-25 d-flex align-items-center">
          <p class="display-1 text-center mx-auto text-secondary font-weight-bolder mb-0">
            Seller Account
          </p>
        </div>
        <div class="text-center h-50 d-flex align-items-center">
          <b-img :src="imgUrl" class="img-fluid mx-auto" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->
      <!-- Login-->
      <b-col md="4" class="bg-primary px-1 h-100">
        <div class="h-25 d-flex align-items-end mb-2 mt-1">
          <b-avatar
            class="mx-auto"
            size="12rem"
            :src="require('@/assets/images/logo/06.png')"
            alt="login V2"
          />
        </div>
        <p class="text-center text-light seller-account h2 mb-3 font-weight-bold">
          حساب البائع
        </p>
        <p class="text-center text-secondary sign-in h1 mb-3 font-weight-bold">
          تسجيل دخول
        </p>
        <div>
          <span class="text-light d-flex align-items-center justify-content-center h1">
            👋{{ $t("Login.Welcome_to_MobileMasr!") }}
          </span>
          <span
            class="text-secondary d-flex align-items-center justify-content-center h5"
          >
            {{ $t("Login.please_sign_in") }}
          </span>
          <validation-observer ref="loginValidation">
            <b-form
              class="mx-5 mt-2"
              @submit.prevent
              v-if="forgetPasswordVisible != true"
            >
              <!-- vendor type -->
              <!-- <validation-provider #default="{ errors }" name="Type" rules="required">
                <b-form-group class="bg-white border-secondary" label-for="active" :state="errors.length > 0 ? false:null">
                  <v-select id="type" v-model="vTypeSelect" :state="vTypeSelect === null ? false : true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="vTypeOptions" :selectable="option => ! option.value.includes('select_value')" label="text" placeholder="Choose type"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider> -->
              <!-- email -->
              <b-form-group label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="البريد الإلكترونى"
                  rules="required|email"
                  lang="ar"
                >
                  <b-form-input
                    class="border-secondary"
                    id="login-email"
                    v-model="LoginData.email"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="بريد الألكترونى"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <!--Validate email error-->
                  <small v-if="invalideEmail" class="text-danger">
                    {{ invalideEmailText }}
                  </small>
                  <!--Authorized email error-->
                </validation-provider>
              </b-form-group>
              <!-- forgot password -->
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="كلمة السر"
                  rules="required"
                  lang="ar"
                >
                  <b-input-group
                    class="input-group-merge border-secondary"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="LoginData.password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                      autocomplete="current-password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <!-- Required password error-->
                  <small v-if="invalidePassword" class="text-danger">
                    {{ invalsidePasswordText }}
                  </small>
                  <!-- Password length error-->
                </validation-provider>
              </b-form-group>
              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="LoginData.remember_me"
                  name="checkbox-1"
                  :value="true"
                  :unchecked-value="false"
                >
                  <span class="text-light">{{ $t("Login.Remember_Me") }}</span>
                </b-form-checkbox>
              </b-form-group>
              <b-row class="btns-container">
                <b-col md="6" class="d-flex new-register-container">
                  <b-row>
                    <b-button
                      size="lg"
                      @click="openWeb"
                      variant="none"
                      class="text-secondary"
                    >
                      تسجيل حساب جديد
                    </b-button>
                  </b-row>
                </b-col>
                <b-col md="6" class="d-flex sign-in-btn-container">
                  <b-button
                    size="lg"
                    type="submit"
                    variant="secondary"
                    class="float-right"
                    @click="SubmitLogin"
                  >
                    {{ $t("Login.Signin") }}
                  </b-button>
                </b-col>
              </b-row>
              <b-row class="btns-container">
                <b-col md="6" class="d-flex new-register-container">
                  <b-row>
                    <b-button
                      size="lg"
                      @click="forgetPassword"
                      variant="none"
                      class="text-white"
                    >
                      نسيت كلمة المرور
                    </b-button>
                  </b-row>
                </b-col>
              </b-row>
            </b-form>
            <!-- submit buttons -->
          </validation-observer>

          <validation-observer
            v-if="forgetPasswordVisible"
            ref="ForgetPasswordValidation"
          >
            <div class="btns-container mx-5 mt-2">
              <b-col md="6" class="d-flex new-register-container">
                <b-row>
                  <b-button
                    size="lg"
                    @click="forgetPassword"
                    variant="none"
                    class="text-white"
                  >
                    نسيت كلمة المرور
                  </b-button>
                </b-row>
              </b-col>

              <b-form-group label-for="login-email" class="mt-2">
                <validation-provider
                  #default="{ errors }"
                  name="البريد الإلكترونى"
                  rules="required|email"
                  lang="ar"
                >
                  <b-form-input
                    class="border-secondary"
                    id="forget-password-login-email"
                    v-model="forgetPasswordEmail"
                    :state="errors.length > 0 ? false : null"
                    name="forget-password-login-email"
                    placeholder="ادخل البريد الالكتروني"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <!--Validate email error-->
                  <small v-if="invalideEmail" class="text-danger">
                    {{ invalideEmailText }}
                  </small>
                  <!--Authorized email error-->
                </validation-provider>

                <b-row class="mt-2" v-if="codeValisiable">
                  <b-col md="12">
                    <b-form-group label="code" label-for="code">
                      <validation-provider
                        #default="{ errors }"
                        name="code"
                        vid="code"
                        rules="required"
                      >
                        <b-form-input
                          id="code"
                          v-model="code"
                          type="number"
                          :state="errors.length > 0 ? false : null"
                          placeholder="من فضلك ادخل الكود"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="mt-2" v-if="showPasswordInputs">
                  <b-col md="6">
                    <b-form-group label="Password" label-for="password">
                      <validation-provider
                        #default="{ errors }"
                        name="Password"
                        vid="Password"
                        rules="required|password"
                      >
                        <b-form-input
                          id="password"
                          v-model="vendorData.password"
                          type="password"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Password"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Confirm Password" label-for="c-password">
                      <validation-provider
                        #default="{ errors }"
                        name="Password Confirm"
                        rules="required|confirmed:Password"
                      >
                        <b-form-input
                          id="c-password"
                          v-model="vendorData.password_confirmation"
                          type="password"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Re-type Password"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form-group>

              <b-row class="btns-container">
                <b-col md="4" class="d-flex new-register-container">
                  <b-row v-if="showPasswordInputs && codeValisiable">
                    <b-button
                      size="lg"
                      @click="openWeb"
                      variant="none"
                      class="text-secondary"
                    >
                      تسجيل حساب جديد
                    </b-button>
                  </b-row>
                </b-col>
                <b-col
                  v-if="!showPasswordInputs && !codeValisiable"
                  md="6"
                  class="d-flex sign-in-btn-container"
                >
                  <b-button
                    size="lg"
                    type="submit"
                    variant="secondary"
                    class="float-right"
                    @click="sendForgetPasswordMail"
                  >
                    ارسال بريد الكتروني
                  </b-button>
                </b-col>
              </b-row>

              <b-row class="btns-container" v-if="codeValisiable && !showPasswordInputs">
                <b-col md="4" class="d-flex new-register-container">
                  <b-row>
                    <b-button
                      size="lg"
                      @click="codeValisiable = false"
                      variant="none"
                      class="text-secondary"
                    >
                      تغيير البريد الالكتروني
                    </b-button>
                  </b-row>
                </b-col>
                <b-col md="6" class="d-flex sign-in-btn-container">
                  <b-button
                    size="lg"
                    type="submit"
                    variant="secondary"
                    class="float-right"
                    @click="checkForgetPasswordCode"
                  >
                    التحقق من الكود
                  </b-button>
                </b-col>
              </b-row>

              <b-row class="btns-container" v-if="showPasswordInputs">
                <b-col md="4" class="d-flex new-register-container"> </b-col>
                <b-col md="6" class="d-flex sign-in-btn-container">
                  <b-button
                    size="lg"
                    type="submit"
                    variant="secondary"
                    class="float-right"
                    @click="ChangePassword"
                  >
                    تغيير كلمة المرور
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </validation-observer>
        </div>
      </b-col>
      <!-- <b-col md="4" class="d-flex align-items-center auth-bg px-1">
        <b-col sm="8" md="6" lg="10" class=" mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            <b-col sm="8" md="6" lg="8" class=" my-5 mx-auto">
              <b-row class="d-flex align-items-center justify-content-center">
              </b-row>
            </b-col>
          </b-card-title>
          <b-card-text class="mb-4"> </b-card-text> -->
      <!-- form -->

      <!-- </b-col>
      </b-col> -->
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */

import VuexyLogo from "@core/layouts/components/Logo.vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import eventBus from "@/libs/bus";
export default {
  components: {
    VuexyLogo,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      invalidePasswordText: "Your password must be equel or more than 6 Letters",
      invalidePassword: false,
      invalideEmailText: "بريدك الإلكتروني أو كلمة المرور غير صحيحة",
      invalideEmail: false,
      status: "",
      LoginData: {
        password: "",
        email: "",
        remember_me: false,
      },
      forgetPasswordEmail: "",
      forgetPasswordVisible: false,
      showPasswordInputs: false,
      codeValisiable: false,
      vendorData: {
        password: "",
        password_confirmation: "",
      },
      // vTypeSelect: null,
      // vTypeOptions: [
      //   {
      //     value: "agency",
      //     text: "Agency",
      //   },
      //   {
      //     value: "seller",
      //     text: "Seller",
      //   },
      // ],
      sideImg: require("@/assets/images/pages/Group 470.png"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      isactive: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (this.$store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  watch: {
    vTypeSelect: function () {
      localStorage.setItem("vType", this.vTypeSelect.value);
    },
  },
  methods: {
    openWeb() {
      window.open(`https://mobilemasr.com/join-us`, "_blank");
    },
    // Start Login
    SubmitLogin() {
      this.isactive = true;
      if (this.LoginData.password.length < 6) {
        //check validate passowrd
        this.isactive = false;
        this.invalidePassword = true;
      } else {
        this.invalideEmail = false;
        this.invalidePassword = false;
        // check login validation
        return new Promise((resolve, reject) => {
          this.$refs.loginValidation.validate().then((success) => {
            if (success) {
              //start request from api
              this.$store
                .dispatch("login", this.LoginData)
                .then(() => {
                  // Validate Form Alert

                  this.$swal({
                    position: "center",
                    icon: "success",
                    title: "You are logged in",
                    showConfirmButton: false,
                    timer: 1000,
                  });
                  // this.$router.push();
                  this.isactive = false;
                })
                .catch((error) => {
                  this.$swal({
                    position: "center",
                    icon: "error",
                    title: this.invalideEmailText,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  if (error.response.status === 401) {
                    this.invalideEmail = true;
                    this.isactive = false;
                  }
                });
            }
          });
        });
      }
    },

    forgetPassword() {
      this.forgetPasswordVisible = true;
      if (this.LoginData.email != "") {
        this.forgetPasswordEmail = this.LoginData.email;
      }
    },
    sendForgetPasswordMail() {
      // check login validation
      return new Promise((resolve, reject) => {
        this.$refs.ForgetPasswordValidation.validate().then((success) => {
          if (success) {
            //start request from api
            axios
              .post("forgetPassword/sendmail", { email: this.forgetPasswordEmail })
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "تم ارسال كود كلمة المرور عبر بريدك الالكتروني",
                  showConfirmButton: false,
                  timer: 1500,
                });

                this.codeValisiable = true;
              })
              .catch((err) => {
                // this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  this.$swal({
                    position: "center",
                    icon: "error",
                    title: err.response.data.data,
                    showConfirmButton: false,
                    timer: 2000,
                  });
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
      });
    },

    checkForgetPasswordCode() {
      // check login validation
      return new Promise((resolve, reject) => {
        this.$refs.ForgetPasswordValidation.validate().then((success) => {
          if (success) {
            //start request from api
            axios
              .post("forgetPassword/checkForgetPassCode", {
                email: this.forgetPasswordEmail,
                code: this.code,
              })
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "تم التحقق من الكود الخاص بك",
                  showConfirmButton: false,
                  timer: 1500,
                });

                this.showPasswordInputs = true;
              })
              .catch((err) => {
                // this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  this.$swal({
                    position: "center",
                    icon: "error",
                    title: err.response.data.data,
                    showConfirmButton: false,
                    timer: 2000,
                  });
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
      });
    },

    ChangePassword() {
      // check login validation
      return new Promise((resolve, reject) => {
        this.$refs.ForgetPasswordValidation.validate().then((success) => {
          if (success) {
            //start request from api

            this.vendorData.code = this.code;
            this.vendorData.email = this.forgetPasswordEmail;

            axios
              .post("forgetPassword/ChangePassword", this.vendorData)
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "تم تغيير كلمة المرور بنجاح",
                  showConfirmButton: false,
                  timer: 1500,
                });

                this.showPasswordInputs = false;
                this.forgetPasswordVisible = false;
                this.codeValisiable = false;
              })
              .catch((err) => {
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  this.$swal({
                    position: "center",
                    icon: "error",
                    title: err.response.data.data,
                    showConfirmButton: false,
                    timer: 2000,
                  });
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
      });
    },
  },
  mounted() {
    localStorage.removeItem("token");
    localStorage.removeItem("adminstration");
    localStorage.removeItem("userData");
    // localStorage.removeItem("vType");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    $cookies.remove("token");
    // eventBus.$emit("vType", null);
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.logo {
  height: 30%;
}
@media only screen and (max-width: 425px) {
  .seller-account-image {
    display: none;
  }
  .seller-account {
    margin-bottom: 1rem !important;
  }
  .sign-in {
    margin-bottom: 1rem !important;
  }
  .new-register-container {
    justify-content: center;
  }
  .sign-in-btn-container {
    justify-content: center;
  }
  .btns-container {
    display: flex;
    flex-direction: column-reverse !important;
  }
}
</style>
