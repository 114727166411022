<template>
  <div v-if="Categories">
    <b-row>
      <b-col  md="6">
        <b-card class="h-100 position-relative">
          <p class="mb-2 shadow rounded py-1 px-2 text-secondary h4 font-weight-bold fit-content mx-auto">
            بيع مباشر
          </p>

          <p class="h5 mb-1">
            تستطيع من خلال هذه الخدمة أن تبيع أي جهاز للعميل مباشرة من محلك
            الخاص مباشرة بدون أي مصاريف شحن أو ضمان
          </p>
          <p class="h5 mb-1">لكي تضمن نجاح هذه المعاملة عليك الآتي:</p>
          <ol class="h5">
            <li class="mb-1">
              عليك أن تفحص الموبايل بالتطبيق والـتاكد من صحة البيانات
            </li>
            <li class="mb-1">
              أن توضح للعميل أن هذه المعاملة أن الإرجاع سيتم من خلالك
            </li>
            <li class="mb-1">
              في حالة الدفع بالتقسيط عليك إحضار صورة بطاقة صاحب الحساب
            </li>
            <li class="mb-1">
              عليك تسليم العميل الجهاز بعد عملية الدفع بنفس الحالة والمواصفات
              التي تم الإتفاق عليها وأي عدم توافق سوف يتم تجميد الرصيد على حسابك
            </li>
          </ol>

          <div class="mt-auto text-center position-absolute btn-container">
            <!-- <b-button variant="secondary" block class="rounded mx-auto" to="/used/Product/Add/In-Store">أضف
              المنتج</b-button> -->
          </div>
        </b-card>
      </b-col>
      <b-col  md="6">
        <b-card class="h-100 position-relative">
          <p class="mb-2 shadow rounded py-1 px-2 text-secondary h4 font-weight-bold fit-content mx-auto">
            بيع منصة
          </p>
          <p class="h5">
            تستطيع من خلال هذه الخدمة أن تعرض أي جهاز للبيع على منصة موبايل مصر
            كما هو متفق عليه في العقود المبرمة بينك وبين شركة موبيتك للحلول
            الإلكترونية
          </p>
          <div class="text-center position-absolute btn-container">
            <!-- <b-button
              variant="secondary"
              block
              class="rounded"
              to="/used/Product/Add/Portal"
              >أضف المنتج</b-button 
            >-->
          </div>
        </b-card>
      </b-col>

    </b-row>

    <b-row class="mt-3">
      <b-col>
        <b-card class="h-100 position-relative" :title="$t('Product_Index.product_type')" >
          <!-- start data table -->
          <b-col cols="12">
            <b-input-group size="lg"   class="d-flex justify-content-center  p-1">
              <select v-model="selectedCategory" placeholder="اختر القسم" size="lg" class="custom-select">
                <option value="" disabled selected>اختر القسم...</option>
                <option v-for="category in Categories" :key="category.id" :value="category.code">
                  {{ category.translation[0].name }}
                </option>
              </select>

            </b-input-group>

            <b-input-group size="lg" class="d-flex justify-content-center  mt-2 p-1" v-if="selectedCategory != ''">
              <select v-model="selected_portal" size="lg" class="custom-select"  >
                <option value="" disabled selected>اختر نوع المنتج...</option>
                <template v-for="category in Categories">

                  <!-- for mobile&tabs category -->
                  <template v-if="(category.code == selectedCategory) && ( selectedCategory == '267' || selectedCategory == '4745' ) ">
                    <option v-if="category.pivot.is_portal_new"
                      :value="'/new/Product/'+ category.id +'/Add/Portal'">
                      <span>بيع منصة جديد</span>
                    </option>


                    <option v-if="category.pivot.is_portal_used"
                      :value="'/used/Product/'+ category.id +'/Add/Portal'">
                      <span> بيع منصة مستعمل </span>
                    </option>


                    <option v-if="category.pivot.is_instore_new"
                      :value="'/new/Product/'+ category.id + '/Add/In-Store'">
                      <span> بيع مباشر جديد </span>
                    </option>


                    <option v-if="category.pivot.is_instore_used"
                      :value="'/used/Product/'+ category.id +'/Add/In-Store'">
                      <span> بيع مباشر مستعمل </span>
                    </option>
                  </template>

                  <!-- for other categories -->
                  <template v-if="(category.code == selectedCategory) && ( selectedCategory != '267' && selectedCategory != '4745' ) ">
                    <option v-if="category.pivot.is_portal_new"
                      :value="'/new/Product/Add/' + category.id +'/Portal'">
                      <span>بيع منصة جديد</span>
                    </option>


                    <option v-if="category.pivot.is_portal_used"
                      :value="'/used/Product/Add/' + category.id +'/Portal'">

                      <span> بيع منصة مستعمل </span>
                    </option>


                    <option v-if="category.pivot.is_instore_new"
                      :value="'/new/Product/Add/' + category.id +'/In-Store'">
                      <span> بيع مباشر جديد </span>
                    </option>


                    <option v-if="category.pivot.is_instore_used"
                    :value="'/used/Product/Add/' + category.id +'/In-Store'">
                      <span> بيع مباشر مستعمل </span>
                    </option>
                  </template>
                </template>
              </select>


              <!-- <select v-model="selectedCategory">
              <option v-for="category in userData.categories" :key="category.id" :value="category.id">
                {{ category.translation[0].name }}
              </option>
            </select> -->
              <!-- <p v-if="selectedCategory">Selected category: {{ selectedCategory }}</p> -->
            </b-input-group>

            <!-- <table class="table table-bordered mt-2">
              <tbody>

                <tr>
                  <th class="text-center">القسم</th>
                  <th class="text-center">بيع منصة جديد</th>
                  <th class="text-center">بيع منصة مستعمل</th>
                  <th class="text-center">بيع مباشر جديد</th>
                  <th class="text-center">بيع مباشر مستعمل</th>

                </tr>
                <tr v-for="category in userData.categories" :key="category.id">
                  <template v-if="category.id == selectedCategory">

                    <th class="text-center">{{ category.translation[0].name }}</th>
                    <td class="text-center">
                      <b-form-radio v-if="category.pivot.is_portal && category.pivot.is_sell_new"
                        v-model="selected_portal" @click="selectedPortal(selected_portal)" name="some-radios"
                        value="/new/Product/Add/Portal">بيع مباشر</b-form-radio>



                    </td>

                    <td class="text-center">
                      <b-form-radio v-if="category.pivot.is_portal && category.pivot.is_sell_used"
                        v-model="selected_portal" @click="selectedPortal(selected_portal)" name="some-radios"
                        value="/used/Product/Add/Portal">بيع مباشر</b-form-radio>



                    </td>

                    <td class="text-center">

                      <b-form-radio v-if="category.pivot.is_in_store && category.pivot.is_sell_new"
                        v-model="selected_portal" @click="selectedPortal(selected_portal)" name="some-radios"
                        value="/new/Product/Add/In-Store">بيع مباشر</b-form-radio>


                    </td>

                    <td class="text-center">
                      <b-form-radio v-if="category.pivot.is_in_store && category.pivot.is_sell_used"
                        v-model="selected_portal" @click="selectedPortal(selected_portal)" name="some-radios"
                        value="/used/Product/Add/In-Store">بيع مباشر</b-form-radio>

                    </td>

                  </template>
                </tr>

              </tbody>
            </table> -->

            <b-col cols="12" class="d-flex justify-content-center mt-2">
              <b-button v-if="selected_portal != ''" class=" rounded  w-50" variant="secondary" block
                :to="selected_portal">أضف منتج
              </b-button>
            </b-col>
          </b-col>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {

  data() {
    return {
      userData: null,
      Categories: null,
      selectedCategory: '',
      selected_portal: '',
      url: ''
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    // sortOptions() {
    // Create an options list from our fields
    // if (Array.isArray(this.userData)) {

    // return this.userData
    //   .filter((f) => f.categories)
    //   .map((f) => ({
    //     text: f.translation[0].name,
    //     value: f.id,
    //   }));
    // }
    // },
  },
  methods: {
    fetchData() {
      axios
        .get("getCategories")
        .then((result) => {
          this.Categories = result.data.data;
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
   
    selectedPortal(selected_portal) {
      // console.log('selected_portal', selected_portal);
      this.url = selected_portal;
    }
  },
};
</script>

<style>
.card-body {
  position: relative;
}

.btn-container {
  bottom: 10px;
  left: 1rem;
  right: 1rem;
}
@media (max-width:992px) {
.custom-select {
      display: inline-block;
      max-width: 100%;
      padding: 1em ;
      font-size: 1rem;
      line-height: 1.5;
      color: #495057;
      vertical-align: middle;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      outline: none;
      cursor: pointer;
    }
}

@media (min-width: 992px) {
.custom-select {
      display: inline-block;
      max-width: 25%;
      padding: 1em ;
      font-size: 1rem;
      line-height: 1.5;
      color: #495057;
      vertical-align: middle;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      outline: none;
      cursor: pointer;
    }
  }

    .custom-select:focus {
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
    .custom-select option {
      font-size: 1rem;
      color: #495057;
    }
    .custom-select[size="lg"] {
      font-size: 1rem;
      padding: 0.75em 1em;
    }
    .custom-select::after {
      content: '\25BC';
      position: absolute;
      right: 1em;
      pointer-events: none;
    }
</style>
